h3 {
  color: #303030;
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
  padding: 0 !important;
  margin: 0;
  text-align: left;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  position: relative;
}
.form_n1 {
  padding: 0 !important;
}
.form_n1 .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: 24px;
}
.form_n2 .row:last-child {
  margin-top: 11px !important;
  float: right;
}
.form_n2 .btn_form:disabled,
.btn_form:disabled {
  background-color: #0000001a !important;
  color: rgba(0, 0, 0, 0.3) !important;
  float: right;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
.btn_form {
  text-transform: none !important;
  background-color: #445aa5 !important;
  color: #ffffff !important;
  border-radius: 15px !important;
  float: right !important;
  cursor: pointer !important;
  pointer-events: auto !important;
  margin: 0 !important;
  width: auto;
  min-width: 132px !important;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
}
.btn_form.mt_0 {
  margin-top: 0 !important;
}
.btn_form:hover {
  opacity: 0.8;
}
.PrivateNotchedOutline-root-1 {
  top: 0 !important;
}
.form_n2 h4 {
  color: #445aa5;
  font-size: 20px;
  line-height: 22px;
  font-weight: normal;
  padding: 42px 0 25px !important;
  margin: 0;
  text-align: left;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  position: relative;
  border-top: 1px solid #5d00ff17;
}
.title_DivStat {
  color: #445aa5;
  font-size: 20px;
  line-height: 22px;
  font-weight: normal;
  padding: 0 15px 15px !important;
  margin: 0;
  text-align: left;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  position: relative;
}
.textTitle p {
  padding-left: 0 !important;
  color: #303030 !important;
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
  font-weight: normal !important;
  padding: 0 15px;
}
.form_n2 .btn_form {
  background-color: #445aa5 !important;
  color: #ffffff !important;
  border-radius: 15px;
  padding: 9px 20px;
  float: right;
  cursor: pointer !important;
  pointer-events: auto !important;
  margin-top: 16px !important;
}
.hideBtn,
.form_n2 {
  display: none !important;
}
.form_n2.shoxForm {
  margin-top: 35px;
  display: block !important;
}
.box_blue span {
  color: #fff;
  font-size: 10px;
  line-height: 10px;
  border-radius: 50%;
  border: 1px solid;
  width: 12px;
  height: 13px;
  text-align: center;
  float: left;
  margin: 2px 3px 0 0;
}
.boxLeftFormNp {
  padding-left: 0 !important;
}
.boxRightFormNp {
  float: right;
  padding-right: 0;
  margin-bottom: 0;
  margin-top: 0;
  padding-right: 15px;
  align-self: flex-start;
  -moz-align-self: flex-start;
  -webkit-align-self: flex-start;
}
.p_0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.boxlistDoc {
  padding: 0;
}
.boxlistDoc ul {
  padding: 16px 16px 0;
  background: #445aa519;
  border-radius: 8px;
  min-height: 0;
  overflow: hidden;
}
.boxlistDoc ul li {
  padding: 0;
  list-style: none;
  color: #303030;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  padding: 0 !important;
  margin: 0 0 16px;
  text-align: left;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.box_blue h2 img {
  float: left;
  margin-right: 8px;
}

.content_section_proposition .Mui-disabled .MuiInputBase-input{
  background-color: rgb(241, 241, 241) !important;
  border-radius: 8px !important;
}
.content_section_proposition .Mui-disabled fieldset{
  border-color:transparent !important;
}
