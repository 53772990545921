.cls_date_right_12 {
  padding: 0 15px;
}
.cls_date_right {
  margin: 15px 0 24px;
  float: right;
  background: rgba(231, 60, 76, 0.09);
  border-radius: 23px;
  text-align: center;
  font-size: 14px !important;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  line-height: 16px !important;
  color: #e73c4c !important;
  padding: 16px !important;
  font-weight: normal !important;
}
.cls_date_right span {
  margin-left: 12px;
}
.cls_content_fiche .cls_box_fiche {
  padding: 16px !important;
  background: #445aa515;
  border-radius: 10px;
  margin: 0 0 24px;
  color: #303030 !important;
  text-align: left;
  font-size: 14px;
}

.cls_content_fiche .cls_box_fiche h2 {
  color: #445aa5 !important;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  text-align: left;
  font-size: 20px;
  margin: 0 0 16px;
  padding: 0 0 0 15px;
  background: url("../../../assets/img/ico_virgule.svg") center left no-repeat;
}

.cls_content_fiche .cls_box_fiche h3 {
  color: #303030 !important;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  text-align: left;
  font-size: 16px;
  margin: 0 0 10px !important;
  padding: 0;
}

.cls_content_fiche .cls_box_fiche p {
  color: #303030 !important;
  text-align: left;
  font-size: 14px;
  margin: 0 0 10px !important;
  padding: 0;
}

.cls_content_fiche .cls_box_fiche p:last-child {
  margin: 0 !important;
  display: inline-block;
  width: 100%;
}
.cls_content_fiche .cls_box_fiche .table {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  border-width: 0 !important;
}
.cls_content_fiche .cls_box_fiche .table tbody,
.cls_content_fiche .cls_box_fiche .table tr {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  border-width: 0 !important;
}

.cls_content_fiche .cls_box_fiche .table td {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 0 8px !important;
  border-width: 0 !important;
  color: #445aa5 !important;
  text-align: left;
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
  font-weight: normal !important;
  line-height: 18px;
}

.cls_content_fiche .cls_box_fiche .table td:last-child {
  color: #e73c4c !important;
  text-align: right;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
  font-weight: normal !important;
}

.cls_content_fiche .form_n2 {
  display: block !important;
}
.cls_content_fiche .form_n2 .row:last-child {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  float: none !important;
}
.cls_content_fiche .box_checkbox {
  padding-right: 0 !important;
}
.cls_content_fiche .form_n2 .btn_form {
  margin-top: 24px !important;
  position: relative;
  overflow: hidden;
}
.cls_content_fiche .form_n2 .row:last-child .box_checkbox {
  margin-bottom: 0 !important;
}
.cls_box_fiche.cls_box_fiche_3 {
  padding: 0 !important;
  background-color: transparent !important;
}
.cls_box_fiche.cls_box_fiche_3 .table th:first-child {
  border-radius: 10px 0 0 10px !important;
}
.cls_box_fiche.cls_box_fiche_3 .table th:last-child {
  border-radius: 0 10px 10px 0 !important;
}
.cls_box_fiche.cls_box_fiche_3 .table th {
  background-color: #445aa515 !important;
  margin: 0 !important;
  padding: 13px 21px !important;
  border-width: 0 !important;
  color: #445aa5 !important;
  text-align: left;
  font-size: 20px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  font-weight: normal !important;
  line-height: 18px;
}
.cls_box_fiche.cls_box_fiche_3 .table th:last-child,
.cls_box_fiche_3 .table td:last-child {
  text-align: right !important;
}

.cls_content_fiche .cls_box_fiche.cls_box_fiche_3 .table td {
  padding: 0 16px 8px !important;
  border-width: 0 !important;
  color: #445aa5 !important;
  line-height: 18px;
}

.cls_content_fiche
  .cls_box_fiche.cls_box_fiche_3
  .table
  tbody
  tr:first-child
  td {
  padding-top: 16px !important;
}

.cls_content_fiche
  .cls_box_fiche.cls_box_fiche_3
  .table
  tbody
  tr
  td:last-child {
  color: #e73c4c !important;
}

.cls_content_fiche .cls_box_fiche.cls_box_fiche_3 .table tbody .cl_ct td {
  background-color: #445aa515 !important;
  margin: 0 !important;
  padding: 13px 21px !important;
  border-width: 0 !important;
  color: #445aa5 !important;
  text-align: left;
  font-size: 20px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  font-weight: normal !important;
  line-height: 18px;
}
.cls_content_fiche
  .cls_box_fiche.cls_box_fiche_3
  .table
  tbody
  .cl_ct
  td:last-child {
  color: #445aa5 !important;
}

.cls_box_fiche.cls_box_fiche_3 .table .cl_ct td:first-child {
  border-radius: 10px 0 0 10px !important;
}
.cls_box_fiche.cls_box_fiche_3 .table .cl_ct td:last-child {
  border-radius: 0 10px 10px 0 !important;
}
.cls_content_fiche .cls_box_fiche.cls_box_fiche_3 .cls_box_fiche,
.cls_content_fiche .cls_box_fiche.cls_box_fiche_3 h2 {
  margin: 0 !important;
}
.cls_content_fiche .cls_box_fiche.cls_box_fiche_3 .table tr:nth-child(5) td {
  padding-top: 8px !important;
}
.cls_content_fiche .cls_box_fiche.cls_box_fiche_2 {
  font-style: italic;
  color: #445aa5 !important;
  text-align: center !important;
  font-weight: bold !important;
  font-family: "Gotham-Rounded-Light" !important;
}

.cls_content_fiche .cls_box_fiche.cls_box_fiche_4 {
  color: #000000 !important;
  background: #fff !important;
  border: 3px solid #445aa5 !important;
  text-align: center !important;
}
.cls_content_fiche .cls_box_fiche.cls_box_fiche_4 h2 {
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  text-align: center !important;
  font-size: 16px !important;
  padding: 0 !important;
  background: transparent !important;
}
.cls_content_fiche .cls_box_fiche.cls_box_fiche_4 p {
  line-height: 19px;
  text-align: center !important;
}

.cls_content_fiche .cls_box_fiche.cls_box_fiche_4 p strong {
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}
.btn_terminer {
  background: transparent linear-gradient(90deg, #e73c4c 0%, #ff2b3e 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 15px !important;
  text-align: center;
  color: #fff !important;
  float: right;
  padding: 12px 18px !important;
  min-width: 138px !important;
}
.cls_left p small {
  font-size: 13px;
  color: #303030;
  margin: 10px 0 0;
  float: left;
}
.btn_form.toggledLoading {
  pointer-events: none !important;
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.btn_form .ld-spin {
  opacity: 0;
  z-index: auto;
  visibility: hidden;
  top: 37%;
  right: 5px;
  position: absolute;
  width: 1em;
  height: 1em;
  transition: all 0.3s;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.btn_form.toggledLoading .ld-spin {
  width: 1em;
  height: 1em;
  top: 37%;
  right: 5px;
  opacity: 1;
  visibility: visible;
}

.ld-spin:after {
  border-radius: 50%;
  border: 0.3em solid currentColor;
  border-left-color: transparent;
  content: " ";
  display: block;
  width: 2em;
  height: 2em;
  box-sizing: border-box;
  transform-origin: 0 0;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
