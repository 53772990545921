.boxStep {
  padding: 0 15px;
  position: relative;
  background-color: #fff;
}

.boxStep:before {
  content: "";
  width: 9px;
  height: 9px;
  background: #ffffff;
  border: 2px solid #445aa5;
  opacity: 0.2;
  border-radius: 15px;
  position: absolute;
  right: 0;
  top: 46.5%;
}

.boxStep:after {
  content: "";
  width: 9px;
  height: 9px;
  background: #ffffff;
  border: 2px solid #445aa5;
  opacity: 0.2;
  border-radius: 15px;
  position: absolute;
  left: 0;
  top: 46.5%;
}

.boxStep:first-child:after,
.boxStep:last-child:before {
  display: none;
}

.boxStep .content-box-step {
  background: #dee0e7;
  box-shadow: 0px 0px 20px #5d00ff1a;
  border-radius: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 19px 16px;
  min-height: 0;
  overflow: hidden;
  font-size: 14px;
  line-height: 26px;
  text-align: left;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

.boxStep.active .content-box-step {
  background: #445aa5;
}

.box {
  width: 300px;
  height: 74px;
  background: #445aa5;
  box-shadow: 0px 0px 20px #5d00ff1a;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divNum {
  width: 36px;
  height: 36px;
  background: #445aa527;
  opacity: 1;
  border-radius: 20px;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #445aa5;
  font-size: 17px;
  position: absolute;
  left: 24px;
}

.boxStep.active .divNum {
  background-color: #ffffff;
  color: #445aa5;
}

.boxStep.active .textBox {
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}

.textBox {
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #445aa5;
  text-transform: uppercase;
  opacity: 1;
  padding-left: 36px;
}

.textBox2 {
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #445aa5;
  text-transform: uppercase;
  opacity: 1;
}

.bar {
  width: 145px;
  height: 0px;
  border: 1px solid #445aa5;
  opacity: 0.2;
}

.circle {
  width: 9px;
  height: 9px;
  background: #ffffff;
  border: 2px solid #445aa5;
  opacity: 0.2;
  border-radius: 15px;
}

.barColor {
  width: 145px;
  height: 0px;
  background: #ffffff;
  border: 2px solid #445aa5;
  opacity: 1;
}

.circleColor {
  width: 9px;
  height: 9px;
  background: #ffffff;
  border: 2px solid #445aa5;
  opacity: 1;
  border-radius: 15px;
}

.ddn {
  width: 300px;
  height: 46px;
  border: 1px solid #9aa0ac;
  border-radius: 8px;
  opacity: 1;
}

.dde {
  width: 300px;
  height: 46px;
  /*     background: #2D3338 ;
    border: 1px solid #303030; */
  border-radius: 8px;
  text-align: left;
  letter-spacing: 0.28px;
  color: #303030;
}

.colend {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.colcenter {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 8px;
}

.r-15 {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.rw {
  margin-bottom: 20px;
}
.th {
  border: 1px solid;
}
.colcenter:before {
  content: "";
  width: 100%;
  height: 2px;
  background: #445aa5;
  opacity: 0.2;
  border-radius: 15px;
  position: absolute;
  right: 0;
  top: 42%;
}

.colstart {
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
}

/*
.selectInput {
  width: 121px;
  height: 46px;
  border: 1px solid #303030;
  border-radius: 15px;
}
*/
/* ///////////////////// toggle button on/off //////////////////// */

.inputCheckbox {
  position: relative;
  width: 80px;
  height: 31px !important;
  background: #e73c4c;
  -webkit-appearance: none;
  border-radius: 30px;
  outline: none;
  transition: 0.4s;
  cursor: pointer;
}

.inputCheckbox:checked {
  background: #445aa5;
  border-radius: 30px;
  opacity: 1;
}

.inputCheckbox::before {
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #e73c4c;
  text-align: center;
  content: "Non";
  left: 5px;
  top: 5px;
  width: 40px;
  height: 21px;
  background: #fff;
  border-radius: 50px;
  font-weight: bold;
}

.inputCheckbox:checked::before {
  left: 35px;
  content: "Oui";
  color: #445aa5;
}

.toggle {
  position: relative;
  display: inline;
}

/*
label {
  position: absolute;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  pointer-events: none;
}
*/
.onbtn {
  bottom: 15px;
  left: 15px;
}

.ofbtn {
  bottom: 15px;
  right: 14px;
  color: #34495e;
}

/* ///////////////////////////////// end toggle button ///////////////////////////////////// */
.textToggle {
  text-align: left;
  color: #303030;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
  font-size: 14px;
}

.textBudget {
  text-align: left;
  font-size: 20px;
  color: #445aa5;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  margin: 0 0 16px;
}

.textBudgetv2 {
  text-align: left;
  font-size: 20px;
  color: #445aa5;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  margin: 0 0 8px;
  padding: 0 0 9px;
  line-height: 22px;
  position: relative;
}

.textBudgetv2::after {
  content: "";
  display: block;
  bottom: 0;
  left: 0;
  width: 58px;
  height: 5px;
  background-color: #e73c4c;
  border-radius: 20px;
  position: absolute;
}

.divCotisation {
  margin-bottom: 16px;
  background: #f6f7fa;
  border-radius: 15px;
  padding: 24px 15px;
}

.divText {
  margin-bottom: 24px;
  color: #303030 !important;
  font-size: 14px;
  line-height: 19px;
}

.MuiOutlinedInput-root {
  width: 100%;
}

.colend .MuiOutlinedInput-root {
  width: auto;
  min-width: 121px;
}

.selectCotisation em {
  text-align: left;
  color: #9aa0ac;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
  text-align: left;
  font-size: 14px;
}

.divTxB {
  text-align: left;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  font-size: 14px;
  color: #303030;
  display: flex;
  align-items: center;
}

.divinfo {
  width: 74px;
  height: 74px;
  background: #e73c4c;
  box-shadow: 0px 15px 30px #e73c4c34;
  opacity: 1;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.divinfo:hover {
  transform: scale(1.05);
}

.iconhelp {
  width: 60px;
  height: 60px;
  color: #ffffff;
  opacity: 1;
}

.icon-search {
  color: #9aa0ac;
}

.iconhelpR {
  fill: #445aa5 !important;
  width: 16px !important;
  height: 16px !important;
  padding: 0 !important;
  cursor: pointer;
}

/*
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 138px;
  height: 48px;
  background: #dadce0 ;
  border-radius: 15px;
  opacity: 1;
  font: normal normal medium 14px/20px Gotham Rounded;
  letter-spacing: 0px;
  color: #ffffff;
}
*/
.iconCheck {
  position: absolute;
  width: 60px;
  height: 60px;
  color: #ffffff;
  visibility: hidden;
}

.infoBulle {
  position: relative;
}

.boxinfo {
  position: absolute;
  width: 418px;
  min-height: 20px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 30px #00000029;
  border-radius: 10px;
  padding: 15px;
  transition: all 0.5s ease-in-out;
  transform: translateY(100%);
  bottom: 0px;
  opacity: 0;
  z-index: 99;
}

.boxinfo h3 {
  color: #e73c4c !important;
  font-size: 18px;
  line-height: 23px;
  padding: 0;
  margin: 0 0 16px;
  text-align: left;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

.showInfoBulle .boxinfo {
  transform: translateY(0%);
  opacity: 1;
  bottom: 90px;
}

.showInfoBulle .iconCheck {
  visibility: visible;
}

.showInfoBulle .iconhelp {
  visibility: hidden;
}

.iconAss {
  color: #e73c4c;
  opacity: 1;
  font-size: 22px !important;
  float: left;
  margin: 0 8px 0 0;
}

.divContainerInfo {
  width: 100%;
  min-height: 20px;
  overflow: hidden;
}

.divContainerInfo .listeInfo {
  text-align: left;
  font-size: 14px;
  color: #303030;
  padding: 0 0 5px 11px;
  display: block;
  position: relative;
}

.divContainerInfo .listeInfo:before {
  position: absolute;
  left: 0;
  top: 8px;
  border: 2px solid #e73c4c;
  width: 6px;
  height: 6px;
  content: "";
  display: block;
  z-index: 1;
  border-radius: 50%;
}

.btnActive {
  text-decoration: none;
  background: #e73c4c;
  float: right;
  color: #fff !important;
}

.DivStat {
  background: #f6f7fa;
  border-radius: 15px;
  padding: 24px 10px;
  margin: 15px 0 24px;
}

/* ///////////////////////////////// button checkbox  /////////////////////////////// */

.formCheck {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  border-radius: 6px;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  cursor: pointer;
  font-family: sans-serif;
  border: 2px solid #9aa0ac;
}

.formCheck::after {
  font-weight: 200;
  content: "";
  background: url("../../../assets/img/check-solid.svg") center no-repeat;
  width: 100%;
  height: 100%;
}

.formCheck:hover {
  opacity: 0.8;
}

.formCheck:checked {
  border: 2px solid #e73c4c;
  background-color: #e73c4c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formCheck:checked::after {
  display: block;
}

/* ///////////////////////////////// end button checkbox  /////////////////////////////// */
.colcheck {
  display: flex;
}

.colcheck .iconhelpR {
  margin: 3px 0 0 !important;
}

.labeltext {
  text-align: left;
  color: #303030;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
}

.labeltext p {
  text-align: left;
  color: #303030;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
}
.labeltext p .iconhelpR {
  margin-top: -6px !important;
}

.DivRachat {
  min-height: 0;
  overflow: hidden;
  background: #445aa5;
  border-radius: 15px;
  opacity: 1;
  padding: 17px 12px 0;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
}

.DivRachat .row {
  margin-bottom: 16px;
}

.DivRachat .col-5 {
  display: flex;
  align-items: center;
}

.titleBox {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 8px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

.textStyle {
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.divNumber {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  padding: 9px 13px;
  width: 87px;
  text-align: left;
  margin-right: 8px;
  float: left;
  color: #445aa5;
}

.textSpan {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: large;
}

.textTitle {
  padding: 0 0 24px;
  text-align: left;
  color: #445aa5;
  font-size: 20px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}
.textTitle1 {
  padding: 0 0 24px;
  text-align: left;
  color: #445aa5;
  font-size: 20px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

.sous_text {
  text-align: left;
  color: #000;
  font-size: 15px;
  font-weight: 100 !important;
}

.textTitle {
  padding: 0 0 24px;
  text-align: left;
  color: #445aa5;
  font-size: 20px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

.sous_text {
  text-align: left;
  color: #000;
  font-size: 18px;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif;
}

.divTableCotisation {
  background: #ffffff;
  border-radius: 15px;
  padding: 24px;
}

.clsTitle {
  margin: auto auto 15px;
  background-color: rgba(231, 60, 76, 0.08);
  border-radius: 50px;
  padding: 12px 30px;
  display: table;
  width: auto !important;
}

.text1 {
  text-align: center;
  color: #303030;
  font-size: 20px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  margin: 0 0 24px !important;
  line-height: 27px;
}

.text2 {
  text-align: center;
  color: #e73c4c;
  font-size: 20px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  margin-left: 3px;
}

.clsTable {
  margin: 0;
}

.clsTable table {
  width: 100%;
  margin: 0 0 15px !important;
  border-width: 0 !important;
}

table.tableRentes th {
  border-top: 1px solid #dddddd;
  background: rgba(68, 90, 165, 0.06);
  color: #445aa5;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

table.tableRentes th:first-child {
  width: 290px;
  padding-left: 13px;
  padding-right: 13px;
}

table.tableRentes tr:first-child th:last-child {
  text-align: center;
  background: #eceef6 !important;
  padding: 15px;
  border-top: 0 solid #dddddd !important;
}

table.tableRentes tr:first-child th:first-child {
  text-align: center;
  vertical-align: top;
  padding-top: 15px;
  border-top: 0 solid #dddddd !important;
}

table.tableRentes tr {
  border-top: 0 solid #dddddd !important;
}

.tableRentes tr td {
  color: #445aa5;
  text-align: center;
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  padding: 10px;
  border-top: 1px solid #dddddd;
}

.tableRentes tr td.td1 {
  color: #000000;
  font-size: 12px;
  border-width: 0;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

/* /////////////////////////// page 2  form //////////////////////////// */
.error {
  color: #ed1c24;
}

.inputSmallError {
  border-color: #ed1c24;
}
.MuiSelect-select .MuiSelect-select,
div.Pays_de_naissance
  div.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
div.Indicatif
  div.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  opacity: 1;
}

.inputSmallValid fieldset {
  border-color: #445aa5;
  border-color: #445aa5 !important;
}

.default_value {
  text-align: left;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  letter-spacing: 0.28px;
  color: #9aa0ac;
}

.selectLarge {
  border: 1px solid #9aa0ac;
  border-radius: 8px;
  opacity: 1;
}

.BtnEngTeleActive {
  width: 216px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #e73c4c !important;
  color: #ffffff !important;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
}

.btnText {
  padding-top: 0;
  height: 14px;
  text-align: center;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  letter-spacing: 0px;
  background: transparent;
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  opacity: 1;
}

.divCheck {
  min-height: 49px;
  background: #445aa519;
  border-radius: 8px;
  opacity: 1;
  padding: 12px;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}
.textDT {
  font-size: 13px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
}

.btnActiveTele {
  float: right;
  width: auto !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.btnActiveTele:hover,
.btnActiveTele:active,
.btnActiveTele:focus {
  background: #445aa5 !important;
  opacity: 0.8;
}

.form-check-input {
  width: 26px;
  height: 26px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  margin-right: 10px;
  color: #303030;
  opacity: 1;
}

.form-check-label {
  color: #000000;
  text-align: left;
  letter-spacing: 0px;
  font-size: 15px;

  color: #303030;
  margin-top: 6px;
  opacity: 1;
}

.form-check-input:checked {
  background-color: #e73c4c;
  border-color: #e73c4c;
}

.Margin {
  margin-right: 120px;
}

.txt_form_Toggle {
  padding-left: 0 !important;
  color: #303030 !important;
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
  font-weight: normal !important;
  margin-left: 15px;
  padding-bottom: 12px;
}
.hr {
  margin: 20px 0 20px 0;
  background-color: #bebec0;
}
.div_box {
  border-radius: 10px;
  height: 100px;
  display: flex;
  background-color: rgb(255, 255, 255);
}

.btnODDActive {
  height: 48px;
  background: #e73c4c;
  border-radius: 15px;
  opacity: 1;
}

.btnODD {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dadce0;
  border-radius: 15px;
  opacity: 1;

  letter-spacing: 0px;
  color: #ffffff;
}

.divHidden {
  right: -350px;
  display: none;
}

.titleBoxDiv {
  text-align: left;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  color: #445aa5;
  font-size: 14px;
}

.interaction {
  margin-left: 16px;
  padding: 4px 9px 9px;
  background: #fff;
  border-radius: 50%;
  margin-top: 3px;
  cursor: pointer;
  border: 1px solid #fff;
  margin-bottom: 5px;
}
.interaction:hover {
  background: #ededed;
  border: 1px solid #fff;
}
.interaction.active {
  background: #fff !important;
  border-color: #ededed !important;
}

.box-toggle {
  position: absolute;
  left: 0;
  top: 0;
}

.divButton .btn {
  color: #ffffff !important;
}

.divStat {
  background: #ffffff;
  border-radius: 15px;
  padding: 24px;
  margin: 24px 0 0;
}

.MT-MB {
  margin: 20px 0 40px 0;
}

.MT-MB-V2 {
  margin: 5px 0 5px 0;
}

.MT-MB-V3 {
  margin: 20px 0 15px 0;
}

/* /////////////////////////////////////////////////////////// Page Souscription  /////////////////////////////////////////////////////////// */

.form-check-input {
  width: 26px;
  height: 26px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  margin-right: 10px;
  color: #303030;
}

.form-check-label {
  color: #000000;
  text-align: left;
  letter-spacing: 0px;
  font-size: 15px;
  font-weight: normal;
  color: #303030;
  margin-top: 6px;
}

.form-check-input:checked {
  background-color: #e73c4c;
  border-color: #e73c4c;
}

.MR {
  margin-right: 48px;
}

.divNT {
  height: 46px;
  border: 1px solid #9aa0ac;
  border-radius: 8px;
}

.divIBAN {
  height: 46px;
  border: 1px solid #9aa0ac;
  border-radius: 8px;
}

.divBIC {
  height: 46px;
  border: 1px solid #9aa0ac;
  border-radius: 8px;
}

.divNB {
  height: 46px;
  border: 1px solid #9aa0ac;
  border-radius: 8px;
}

.divShow {
  position: fixed;
  top: 0;
  right: 0px;
  width: 353px;
  height: 100%;
  background: #fff;
  box-shadow: 0px 0px 5px #5d00ff17;
  border-radius: 10px 0px 0px 10px;
  display: block;
  padding: 25px 10px 20px;
  overflow: auto;
}

.divShow .iconhelpR {
  width: 32px !important;
  height: 32px !important;
}

.divHidden {
  right: -350px;
  display: none;
}

.divHiddenQ2 {
  right: -350px;
  display: none;
}

.iconClose {
  color: #303030;
  cursor: pointer;
}

.QdivBody {
  width: 100%;
  text-align: left;
  color: #303030;
  font-size: 14px;
  font-weight: bold;
}

.ErrorPhone {
  text-align: left;
  letter-spacing: 0.24px;
  color: #ed1c24;
  opacity: 1;
  font-size: 12px;
}

.PhoneLabel {
  color: #9aa0ac;
  font-size: 10px;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif;
}

.ErrorLabel {
  color: #ed1c24;
}

.ValidLabel {
  color: #445aa5;
}

.form-control-valide {
  border: 1px solid #445aa5 !important;
}

.Mt_Max {
  margin-top: 16px;
}

.Div-Mr {
  margin-right: 50px;
}

.MB_Max {
  margin-bottom: 15px;
}
.row.d-top {
  align-items: flex-start;
}
.divIconInfo {
  background: rgba(45, 51, 56, 0.05);
  border-radius: 8px;
  margin: 8px 0 0;
  padding: 16px 16px 16px 26px !important;
  text-align: justify;
}

.iconInfo {
  color: #445aa5;
  font-size: 15px !important;
  left: -17px;
  top: 4px;
  position: absolute;
}

.textInfo {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #445aa5;
  position: relative;
}

.testBorder {
  border: 2px solid #000;
}

.display {
  display: none;
}

/* <--------------------------------------------------------------- Modal style ------------------------------------------------> */

.Modal_Div_Icon {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.icon_CheckCircle {
  color: cadetblue;
}

.P-Modal-Sous {
  font-size: 16px;
  font-weight: 400;
}

.text-Modal-Sous {
  font-size: 14px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

.Div-Signature {
  height: 280px;
  background: #445aa519;
  border-radius: 20px;
  padding: 20px 20px;
}

.title-h3 {
  text-align: left;
  color: #445aa5;
  font-size: 16px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  margin-left: 10px;
}

.contentBoxGris {
  padding: 16px 0;
  background-color: #fff;
  text-align: left;
  color: #445aa5;
  font-size: 16px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  border-radius: 15px;
  margin: 0 0 8px;
  display: block;
  width: 100%;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contentBoxGris p {
  flex: 2;
  color: #445aa5;
  font-size: 16px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}
.contentBoxGris .btn {
  background-color: #445aa5 !important;
  padding: 16px 29px;
  color: #fff !important;
  line-height: 16px;
  border-radius: 15px;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif;
  float: right;
  margin-left: 5px;
  font-weight: bold;
  flex: 1;
}

.contentGris2 {
  background: #f6f7fa;
  border-radius: 15px;
  padding: 32px 30px 32px 10px;
  margin: 24px 0;
}

.mb_40 {
  margin-bottom: 40px;
}

.hr.w-100 {
  padding: 0;
  height: 1px !important;
  background-color: #dadce0;
  opacity: 1;
  margin: 0 !important;
  border-width: 0 !important;
}

.boxCheckedyesno {
  margin: 24px 0;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif;
  position: relative;
  padding: 0 0 0 92px !important;
  display: flex;
  align-items: center;
  color: #303030;
  font-size: 14px;
  line-height: 16px;
  min-height: 31px;
}

.infoH5 {
  position: relative;
  text-align: justify;
  color: #445aa5;
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
  margin-bottom: 0 !important;
  padding-left: 5px;
}

.infoH5 span {
  color: #445aa5;
  font-size: 10px;
  line-height: 10px;
  border-radius: 50%;
  border: 1px solid;
  width: 12px;
  height: 13px;
  text-align: center;
  float: left;
  margin: 2px 3px 0 0;
  position: absolute;
  left: -14px;
  top: 0;
}

.contentGris3 {
  background: #f6f7fa;
  border-radius: 8px;
  padding: 16px 16px 16px 26px;
  /*margin-bottom: 24px;*/
}

.box_blue {
  background: #445aa5;
  border-radius: 15px;
  padding: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: left;
  font-size: 14px;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif;
  color: #ffffff;
  font-weight: normal !important;
  line-height: 25px;
}

.box_blue p {
  text-align: left;
  font-size: 14px;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif;
  color: #ffffff;
  font-weight: normal !important;
  line-height: 25px;
}

.box_blue h2 {
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 16px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  margin: 0 0 8px;
  line-height: 22px;
}

.box_blue h3 {
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 16px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  margin: 0 0 18px;
  line-height: 18px;
}
.box_blue h3 img {
  margin: 0 8px 0 0;
}

.navigationSection {
  margin: 24px 0 0;
}

.navigationSection .btn {
  margin-left: 15px;
}

/* <---------------------------------- Style \ Div Phone -----------------------------------------> */

.title_phone {
  text-align: left;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  letter-spacing: 0.28px;
  color: #445aa5;
  font-weight: 500;
}

.div-phone {
  margin-top: 24px;
}

.Div_num_phone {
  height: 46px;
  border: 1px solid #9aa0ac;
}

.row > .col-3 {
  padding: 0 10px 0 0;
}

.row > .col-9 {
  padding: 0;
}

/* <----------------- select phone ----------------> */

div.Select_Phone_Code input.MuiSelect-nativeInput {
  border: 0 !important;
}

.fieldset_Valide {
  border-width: 2px !important;
  border-color: #445aa5 !important;
}

/* <------------------------------ bar select ----------------------------------> */

.Select_Phone_Code .MuiSelect-select:after {
  position: absolute;
  right: 35px;
  top: 17px;
  width: 1px;
  height: 16px;
  content: "";
  background-color: #9aa0ac;
}

div.Select_Phone_Code {
  height: 48px;
}

div.Div_num_phone-mobile
  input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
div.Div_num_phone-fixe
  input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 20px !important;
}

.input_valid {
  border-color: #445aa5 !important;
  border-radius: 8px;
}
.without_fieldset {
  border: none !important;
}
.Color_input_date {
  color: #000 !important ;

  border: none !important;
}

.input_invalid {
  border-color: #ed1c24 !important;
  border-radius: 8px;
}

.Valid_Label {
  color: #445aa5 !important;
}

.Inalid_Label {
  color: #ed1c24 !important;
  margin-left: 0 !important;
}

.Label {
  color: "#9AA0AC" !important;
}

.error_text {
  color: #ff0707 !important;
}

.MuiOutlinedInput-root svg {
  color: #445aa5 !important;
}

/*  <-----------------------------------------------  Bar Chart -----------------------------------------------------------> */
.recharts-yAxis Line {
  stroke: transparent;
  fill: none;
}

.recharts-wrapper
  > svg
  > .recharts-cartesian-grid
  > .recharts-cartesian-grid-vertical
  Line {
  stroke: transparent;
  fill: none;
}

.recharts-legend-wrapper {
  width: 590px !important;
  height: 85px !important;
  background: rgba(68, 90, 165, 0.06);
  border-radius: 10px;
  top: -81px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  margin: auto !important;
}

.legend-item-0 {
  margin-right: 50px !important;
}

.recharts-surface {
  border-radius: 4px;
  margin-top: 0 !important;
}

.recharts-legend-item-text {
  color: #000 !important;
}

.recharts-legend-icon {
  margin-top: 0 !important;
  stroke: none;
  d: path("M 0 1 h 120 v 120 h -120 Z");
  border-radius: 20px;
}

.secande_text_0 {
  text-align: center !important;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif !important;
  color: #303030 !important;
  font-size: 12px !important;
  margin-left: 40px;
}

.secande_text_1 {
  text-align: center !important;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif !important;
  color: #303030 !important;
  font-size: 12px !important;
  margin-left: 60px;
}
/* <------------------------------------------ Modal Nouvelle proposition ------------------------------------> */
.title_modal {
  text-align: center;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  letter-spacing: 0px;
  font-size: 20px;
  color: #445aa5;
}
.text_1 {
  text-align: center;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  font-size: 13px;
}
.modal-content {
  width: 689px !important;
  border-radius: 15px;
  backdrop-filter: blur(8px) !important;
  -webkit-backdrop-filter: blur(8px) !important ;
  border: 1px solid rgba(0, 0, 0, 0.1) !important ;
}

.modal .btn {
  min-width: 116px;
  background: transparent !important;
  color: #303030;
  border-radius: 15px !important;
  border-width: 0 !important;
  padding: 12px 25px !important;
}
.modal .btn-primary {
  color: #fff;
  background: transparent linear-gradient(90deg, #e73c4c 0%, #ff2b3e 100%) !important;
}

.btn_modal_ok {
  min-width: 82px !important;
  height: 48px;
  border-radius: 15px;
}
.Infoadherent .contentGris3 {
  padding: 16px 16px 16px 26px;
  float: left;
  width: auto;
}
.Infoadherent .infoH5 {
  margin-bottom: 0 !important;
}
.mt-24 {
  margin-top: 24px;
}
.mt-50 {
  margin-top: 50px;
}
.blocDocTelecharge .row {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.blocDocTelecharge .row .textBudget {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.listeChoixDocDnw .col-md-5 {
  padding-left: 40px !important;
  padding-right: 0px !important;
  position: relative;
  box-sizing: border-box;
  min-height: 20px;
  overflow: hidden;
  width: 46.99999%;
  margin-bottom: 8px;
}
.listeChoixDocDnw .col-md-2 {
  width: 6%;
}
.listeChoixDocDnw .col-1 {
  padding-left: 0px !important;
}
.listeChoixDocDnw .boxCeckbox {
  position: absolute;
  left: 0;
  top: 12px;
}
.row_btn {
  margin: 16px 0 24px;
  display: block;
}
#formSimulation {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}

.mb-40 {
  margin-bottom: 40px;
}
.mbt-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
p.Mui-error,
.Mui-error input {
  color: #ed1c24 !important;
}
.Mui-error {
  margin-left: 0 !important;
  color: #ed1c24;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
  fieldset
  /* ,.Mui-error fieldset*/ {
  border-color: #ed1c24 !important;
}
.Mui-error:hover .input_invalid,
.MuiOutlinedInput-root.Mui-focused.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root
  fieldset {
  border-color: #ed1c24 !important;
}

.MuiInputLabel-root.Mui-error,
.error-valide .focus-input input,
.error-valide .MuiFormLabel-filled input,
.error-valide .Mui-focused input[type="date"],
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused.Mui-error,
.Mui-focused span.Mui-error,
.Mui-focused:hover span.Mui-error,
input span,
.MuiOutlinedInput-root.Mui-focused.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root
  input,
.MuiOutlinedInput-root.Mui-focused.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
  input {
  color: #ed1c24 !important;
}
.Mui-focused.Mui-error .MuiSelect-select:before {
  background: url("../../../assets/img/arrow-down-select.svg") right center
    no-repeat !important;
}
.Mui-error .MuiSelect-select:before {
  background: url("../../../assets/img/arrow-down-select_R.svg") right center
    no-repeat !important;
} /* 
.Mui-focused.Mui-error span,
.Mui-focused.Mui-error .MuiSelect-nativeInput {
  color: #445aa5 !important;
}
.Mui-focused.Mui-error fieldset {
  border-color: #445aa5 !important;
} */
#unitsValue {
  border-left: 1px solid #9aa0ac !important;
  position: absolute;
  right: 16px;
  top: 0;
  padding: 11px;
  height: 48px;
  opacity: 0.5;
  font-size: 15px;
  line-height: 28px;
  z-index: 1;
}
#unitsValue:before {
  content: "";
  position: absolute;
  right: 2px;
  top: 3px;
  padding: 0;
  height: 37px;
  width: 29px;
  background: #f6f7fa;
  z-index: -1;
}
#unitsValue.error-valide {
  border-color: #ed1c24 !important;
  color: #ed1c24;
  opacity: 1;
}
.inputVersement input {
  padding-right: 35px !important;
}
.div_Hidden {
  display: none;
}
