.Plaquette {
  width: 187px;
  height: 48px;
  background: transparent;
  border-radius: 15px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #445aa5;
}

.Nouvelle {
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 12px 17px 12px 46px;
  margin: 0 8px;
  text-align: center;
  border: 1px solid #445aa5;
  border-radius: 15px;
  background: url("../../../assets/img/icon-creation-np.svg") center left 14px
      no-repeat,
    linear-gradient(90deg, #e73c4c 0%, #ff2b3e 100%) 0% 0% !important;
}

.Nouvelle:hover {
  opacity: 0.8;
}

.textPro {
  color: #445aa5;
  font-size: 20px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

.divPro {
  position: relative;
}

.divPro::before {
  content: "";
  width: 58px;
  height: 5px;
  border-radius: 20px;
  background: #e73c4c;
  position: absolute;
  border-radius: 20px;
  bottom: 0px;
}

.divIcon {
  width: 46px;
  height: 46px;
  background: url("../../../assets/img/icon_filtre-off.svg") center #eee
    no-repeat;
  border-radius: 23px;
  border-width: 0;
  margin: 0 16px;
  position: relative;
  flex-shrink: 0;
}

.active_Filtre {
  background: #303030;
  border-radius: 10px;
  position: absolute;
  top: -2px;
  right: -16px;
  text-align: center;
  font-size: 9px;
  color: #fff;
  padding: 1px 0;
  border: 2px solid #fff;
  font-weight: bold;
  width: 35px;
  line-height: 14px;
}

.divIcon_Active {
  width: 46px;
  height: 46px;
  background: url("../../../assets/img/icon_filtre-on.svg") center #e73c4c
    no-repeat;
  border-radius: 23px;
  border-width: 0;
  margin: 0 16px;
  flex-shrink: 0;
  position: relative;
}

.download-table-Excel-button {
  width: 46px;
  height: 46px;
  color: #eee;
  border: 0;
  border-radius: 23px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  text-align: center;
  background: url("../../../assets/img/icon-xls.svg") center #eee no-repeat !important;
  float: left;
  flex-shrink: 0;
}
.inputRe {
  border-radius: 15px !important;
  font-size: 14px !important;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif;
  width: 294px !important;
  color: #2d3338 !important;
  padding: 14px 16px 13px 35px !important;
  height: auto !important;
  background: url("../../../assets/img/icon-search.svg") left 13px center
    no-repeat #eee !important;
  border-width: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
  background-size: 14px !important;
}
.divRow {
  flex: 1;
  display: inline-block;
}

.textf {
  color: #303030;
  font-size: 16px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  margin-bottom: 9px;
}

.Label-input-DF {
  text-align: left;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  font-size: 14px;
  color: #445aa5;
}

.row-MV {
  margin: 0 0 15px;
}

.row-MT {
  margin-top: 30px;
}

.data-table-extensions {
  display: none !important;
}

/* <----------------------------------------------------- style div filtre / input Adherent----------------------------------------------------------> */
.input-Per-Rech {
  height: 46px;
  border-radius: 16px;
  border: 1px solid #303030;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  padding: 1px 16px !important;
}

.inputFiltre {
  border: 1px solid #303030;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
}

.inputFiltre fieldset legend {
  width: unset;
}

/* <--------------------------------------------------------------------------------------------------> */

.boxfiltre {
  width: 100%;
  border: 1px solid #9aa0ac;
  border-radius: 10px;
  margin: 0;
  padding: 16px;
}
.boxfiltre .col-2 {
  padding-left: 0 !important;
}
.boxfiltre .col-6 {
  padding-left: 0 !important;
}
.title_content {
  color: #303030 !important;
  font-size: 24px;
  line-height: 34px;
  font-weight: normal;
  padding: 6px 0;
  margin: 0;
  text-align: left;
  display: block;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}

.bloc_btn_header {
  padding-right: 0;
  display: flex;
  justify-content: flex-end;
}

.download-table-xls-button {
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  color: #445aa5;
  font-size: 14px;
  font-weight: normal;
  padding: 12px 17px 12px 46px;
  margin: 0 8px;
  text-align: center;
  border: 1px solid #445aa5;
  border-radius: 15px;
  background: url("../../../assets/img/icon-Plaquette-produit.svg") center left
    14px no-repeat !important;
}

.download-table-xls-button:hover {
  opacity: 0.8;
}

.bloc_panel {
  margin-bottom: 24px;
}

.containerP .container1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #5d00ff17;
  border-radius: 10px;
  padding: 16px;
  min-height: 0;
  overflow: hidden;
}

.sc-llJcti {
  border: 1px solid #000;
  border-radius: 6px;
}

nav.rdt_Pagination {
  justify-content: flex-start !important;
  position: relative !important;
}

nav.rdt_Pagination button.sc-gikAfH:first-child,
nav.rdt_Pagination button.sc-gikAfH:last-child {
  display: none !important;
}
nav.rdt_Pagination button.sc-gikAfH {
  height: 24px !important;
  width: 25px !important;
  background-color: #445aa5 !important;
  border-radius: 6px !important;
  color: #fff !important;
  margin: 2px !important;
  justify-content: center !important;
  fill: rgba(255, 255, 255, 1) !important;
  display: flex !important;
  align-items: center;
}

nav.rdt_Pagination div.jKAZdd,
nav.rdt_Pagination .hoQsHK {
  position: absolute !important;
  right: 10px !important;
}

/* sc-bZkfAO sc-kLLXSd lmGpws OHwJB */
nav.rdt_Pagination span.sc-kMjNwy {
  position: absolute !important;
  right: 50px !important;
}

/* sc-bZkfAO sc-ikZpkk lmGpws kBhHpT */
nav.rdt_Pagination span.sc-ilhmMj {
  position: absolute !important;
  left: 70px !important;
  font-family: "Gotham-Rounded-Light" !important;
  color: #2d3338 !important;
  font-size: 15px !important;
}
nav.rdt_Pagination div.sc-lllmON {
  border: 1px solid #303030;
  color: #303030;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  border-radius: 5px;
}
/* <------------------------------------- Column table / style-------------------------------------> */
div div.rdt_TableHead div.rdt_TableHeadRow div.rdt_TableCol {
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
}

/*  <--------------------------------------- Style Row/Column Table -------------------------------------------> */
div.rdt_TableBody div.rdt_TableRow div.rdt_TableCell:nth-child(2) {
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  color: #445aa5;
}
.rdt_TableBody .rdt_TableRow div.rdt_TableCell:nth-child(2):hover {
  text-decoration: underline;
}

div.rdt_TableBody div.rdt_TableRow div.rdt_TableCell:nth-child(3) {
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  opacity: 1;
  columns: #0000;
}

div.rdt_TableBody div.rdt_TableRow div.rdt_TableCell:nth-child(4) {
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  color: #666666;
}

div.rdt_TableBody div.rdt_TableRow div.rdt_TableCell:nth-child(5) {
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  color: #666666;
}

div.rdt_TableBody div.rdt_TableRow div.rdt_TableCell {
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  columns: #0000;
}

div.rdt_TableBody div.rdt_TableRow div.rdt_TableCell:nth-child(7) {
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
}
.eUeqdG,
.bMgaAx div:nth-child(2) {
  white-space: initial !important;
  text-overflow: inherit !important;
}
.rdt_TableBody .rdt_TableCell:nth-child(7),
.rdt_TableHeadRow .rdt_TableCol:nth-child(7) {
  width: 115px !important;
  max-width: 115px;
  min-width: 115px;
  text-align: center !important;
  justify-content: center;
}
div.rdt_TableBody div.rdt_TableRow div.rdt_TableCell:nth-child(8) {
  font-size: 14px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
}
.rdt_TableBody .rdt_TableCell:nth-child(8),
.rdt_TableHeadRow .rdt_TableCol:nth-child(8) {
  width: 135px !important;
  max-width: 135px !important;
  min-width: 135px !important;
}
/* <-------------------------- update svg sort /columns -------------------------------> */

div.rdt_TableHead
  div.rdt_TableHeadRow
  div.rdt_TableCol
  div.rdt_TableCol_Sortable
  span.sc-kDDrLX {
  opacity: 1 !important;
  transform: none !important;
}
/* .fTxtMi,
.bKBwgj,
.bdnCUi {
  opacity: 1 !important;
  transform: none !important;
} 
 */

/* <------------------- button pagination  next / previous ----------------------> */
#pagination-previous-page svg {
  display: none;
}
#pagination-next-page svg {
  display: none;
}
#pagination-previous-page {
  background: url("../../../assets/img/icon-arrow-left.svg") center no-repeat;
}
#pagination-next-page {
  background: url("../../../assets/img/icon-arrow-right.svg") center no-repeat;
}
/* <---------------------------- style btn rud /data  ----------------------------------> */
.btn_rud {
  width: 32px;
  height: 32px;
  margin: 3px 0;
  border: none;
}
/*.btn_rud:hover{ 
  background-color: #445AA5 0% 0% no-repeat padding-box;
  opacity: 0.15;
} */
.Visualiser {
  background: url("../../../assets/img/icon-visualiser.svg") center no-repeat;
}
.Visualiser:hover {
  background: url("../../../assets/img/icon-visualiser.svg") center #eee
    no-repeat;
  border-radius: 15px;
}
.Modifier {
  background: url("../../../assets/img/icon-Modifier.svg") center no-repeat;
}
.Modifier:hover {
  background: url("../../../assets/img/icon-Modifier.svg") center #eee
    no-repeat;
  border-radius: 15px;
}

.Activer {
  background: url("../../../assets/img/activate.svg") center no-repeat;
}
.Activer:hover {
  background: url("../../../assets/img/activate.svg") center #eee no-repeat;
  border-radius: 15px;
}
.Desactiver {
  background: url("../../../assets/img/desactivate.svg") center no-repeat;
}
.Desactiver:hover {
  background: url("../../../assets/img/desactivate.svg") center #eee no-repeat;
  border-radius: 15px;
}

.Dupliquer {
  background: url("../../../assets/img/icon-edit.svg") center no-repeat;
}
.Dupliquer:hover {
  background: url("../../../assets/img/icon-edit.svg") center #eee no-repeat;
  border-radius: 15px;
}
.Supprimer {
  background: url("../../../assets/img/icon-delete.svg") center no-repeat;
}
.Supprimer:hover {
  background: url("../../../assets/img/icon-delete.svg") center #eee no-repeat;
  border-radius: 15px;
}

#Id-S {
  border: 1px solid #303030;
  border-radius: 5px;
  padding: 2px 5px;
  width: 32px;
  height: 24px;
  margin-right: 5px;
}

#d-num-row {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: "Gotham-Rounded-Light", Arial, sans-serif;
  overflow: hidden;
}

/* <------------------------------------------------------------------------> */

.AutoText {
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  border-width: 0 !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.73);
  position: relative;
  border-radius: 16px;
  height: 46px;
}
.input_S {
  width: 100%;
  border: none;
  font-size: 15px !important;
  color: rgba(0, 0, 0, 0.73);
  padding: 10px 30px 10px 16px !important;
  box-sizing: border-box;
  border-radius: 16px;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
  border: 1px solid #303030;
}
.input_valid input.input_S {
  border-color: #445aa5 !important;
}

input[type="text"].input_S::-webkit-input-placeholder {
  color: #9aa0ac !important;
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
}

.AutoText ul {
  max-height: 237px;
  list-style-type: none;
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 3px;
  padding: 10px;
  margin-top: 0;
  overflow: auto;
  display: none;
}
.AutoText ul::before {
  content: "";
}
.AutoText li {
  padding: 5px;
  cursor: pointer;
  text-align: left;
  font-family: "Gotham-Rounded-Light";
  letter-spacing: 0px;
  color: #9aa0ac;
  font-size: 14px;
}

.AutoText li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}
.input_focused {
  border-color: #445aa5;
}
.input_valid {
  border: 1px solid #303030;
  color: #000;
}
.text_Color {
  color: #445aa5;
  font-size: 14px;
  font-family: "Gotham-Rounded-Light";
}

.Position {
  position: relative;
  z-index: 1;
}
.icon_search {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 13px;
  right: 8px;
}
.rdt_TableCol {
  font-size: 12px !important;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif !important;
}
.gcOZAW {
  background-color: #fff !important;
}
.sc-jqUVSM:hover,
.gcOZAW:hover {
  background-color: #3030300b !important;
  box-shadow: 0px 3px 6px #00000029;
}
.form-select:focus {
  box-shadow: none !important;
  border-color: #445aa5;
}
.boxfiltre .form-check .form-check-input {
  border: 1px solid #9aa0ac !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 6px !important;
  flex-shrink: 0;
}
.boxfiltre .form-check .form-check-input:checked {
  border: 1px solid #e73c4c !important;
}
.boxfiltre .form-check label.form-check-label {
  font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
}
.filtreZone {
  padding: 0 17px 24px;
}
.boxMRdnw {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.boxDownload {
  position: absolute;
  bottom: 10px;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.targetDnw {
  /* background: #edeff6; */
  width: 61px;
  height: 61px;
  border-radius: 50%;
  /* padding: 18px; */
  margin-right: 19px;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}
#ModalDnw {
  width: 370px !important;
  max-width: 370px !important;
  margin: 0 !important;
  position: fixed;
  right: 25px;
  bottom: 14px;
  z-index: -100;
}
#ModalDnw .modal-content {
  width: 100% !important;
  height: auto !important;
  padding: 24px !important;
  border-width: 0 !important;
  box-shadow: 0px 3px 20px #00000029;
}
.boxTitleDnw h3 {
  color: #000000;
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
  padding: 0 0 4px !important;
  margin: 0;
  text-align: left;
  font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
  position: relative;
}
.boxTitleDnw p {
  color: #000000;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  padding: 0 !important;
  margin: 0;
  text-align: left;
  position: relative;
}
.table > :not(:first-child) {
  border-width: 0 !important;
}
.sc-hLBbgP:last-child .gnetL {
  display: none !important;
}
.btnreference {
  background: none !important;
  white-space: break-spaces;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #445aa5;
  font-family: Gotham-Rounded-Medium, Arial, sans-serif;
  font-size: 14px;
  border-width: 0 !important;
}
.btnreference:hover {
  text-decoration: underline;
}
.tooltip {
  position: absolute;
  left: 0;
  top: 0;
}
.pl-0{
  padding-left: 0 !important;
}
.toggledShowDivFiltre{
  display: none;
}
.rdt_TableRow > div ,
.rdt_TableHeadRow > div {
  padding-left:5px !important;
  padding-right: 5px !important;
}