.row > * {
padding-right: 15px !important;
padding-left: 15px !important;
}
.container-fluid {
margin: 0;
padding: 0;
font-family: "Gotham-Rounded-Light", Arial, sans-serif;
}
.row {
margin-left: 0 !important;
margin-right: 0 !important;
padding: 0;
position: relative;
}

.btn {
outline: 0 !important;
-webkit-outline: 0 !important;
-moz-outline: 0 !important;
}
a:focus,
:focus {
outline-width: 0 !important;
}
.btn-check:focus + .btn-success,
.btn,
.btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
box-shadow: none !important;
border-width: 0 !important;
}
body {
background: #445aa5;
font-family: "Gotham-Rounded-Light", Arial, sans-serif;
font-weight: normal;
font-size: 14px;
color: #000000;
height: auto !important;
margin: 0;
padding: 0;
}
a {
color: #000000;
cursor: pointer;
text-decoration: none;
}
*,
*::before,
*::after {
box-sizing: border-box;
}
ul {
padding: 0;
margin: 0;
list-style: none;
}
.wrapper {
background: #445aa5;
font-family: "Gotham-Rounded-Light", Arial, sans-serif;
font-weight: bold;
font-size: 14px;
color: #000000;
height: auto !important;
}
.sidebar-wrapper {
width: 250px;
height: calc(100%);
position: fixed;
top: 0;
left: 0;
bottom: 0;
z-index: 16;
background: #445aa5;
box-shadow: none;
transition: all 0.5s ease-in-out;
padding: 24px 8px 0;
}
.page-wrapper {
position: relative;
}
.page-content-wrapper {
margin-left: 250px;
transition: all 0.5s ease-in-out;
padding: 8px 8px 0px;
}

.sidebar-wrapper .bloc_logo {
text-align: center;
padding: 0 0 25px;
}
.sidebar-wrapper .bloc_logo img {
margin: auto;
}
.sidebar-wrapper #menu {
list-style: none;
padding: 0;
margin: 0;
padding-left: 0 !important;
padding-right: 0 !important;
}
.sidebar-wrapper #menu li {
list-style: none;
padding: 0 0 5px;
display: block;
width: 100%;
margin: 0;
}
.sidebar-wrapper #menu li a {
position: relative;
color: #ffffff;
font-size: 14px;
line-height: 19px;
font-weight: normal;
padding: 16px 12px 16px 55px;
margin: 0;
text-align: left;
display: block;
width: 100%;
text-decoration: none !important;
border-radius: 15px;
min-height: 64px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
}
.sidebar-wrapper #menu li a.m1 {
background: url("assets/img/icon-menu-Souscription_contrats.svg") left 15px
center no-repeat;
}

.sidebar-wrapper #menu li a.m1.active,
.sidebar-wrapper #menu li a.m1.active:hover {
background: url("assets/img/icon-menu-Souscription_contrats.svg") left 15px
center no-repeat,
linear-gradient(90deg, rgb(237, 28, 36) 0%, #ff2b3e 100%) 0% 0% !important;
color: #fff !important;
}
.close_menu {
cursor: pointer;
margin-left: 8px;
}
.page-content {
min-height: calc(100vh - 8px);
margin: 0;
padding: 16px;
border-radius: 20px;
background-color: #f7f8fa;
overflow: hidden;
}
.sidebar-header {
padding: 0;
min-height: 20px;
overflow: hidden;
display: block;
}

.sidebar-header .bloc_logo {
float: left;
margin-left: 2px !important;
transition: all 0.5s ease-in-out;
display: none;
position: absolute;
left: 0;
top: 0;
}
.sidebar-header .bloc_logo a {
padding: 0 !important;
float: left;
}
.sidebar-header .bloc_burger {
float: left;
margin: 3px 0 0;
cursor: pointer;
display: none;
}

.top-header {
padding: 0 0 16px;
background: transparent;
-ms-flex-flow: row nowrap;
flex-flow: row nowrap;
-ms-flex-pack: start;
justify-content: flex-start;
position: relative;
display: -ms-flexbox;
display: flex;
/*
-ms-flex-wrap: wrap;
flex-wrap: wrap; */
-ms-flex-align: center;
align-items: center;
-ms-flex-pack: justify;
justify-content: space-between;
margin-bottom:24px ;
}
.search-bar {
margin: 0 16px 0 0 !important;
position: relative;
width: 229px;
}
.search-bar .input-group {
width: 100%;
flex: 1;
}
.search-bar .form-control {
display: block;
width: 100% !important;
height: 40px !important;
padding: 11px 32px !important;
font-size: 14px !important;
line-height: 19px !important;
color: #2d3338 !important;
/*background: url("../img/Icon-search.svg") center left 9px no-repeat #f1f2f2 !important;*/
border: 0 solid #ccc !important;
border-radius: 8px !important;
-webkit-box-shadow: inset 0 0 0 rgb(0, 0, 0) !important;
box-shadow: inset 0 0 0 rgb(0, 0, 0) !important;
}

::-webkit-input-placeholder {
/* Edge */
color: #2d3338 !important;
}
:-ms-input-placeholder {
/* Internet Explorer 10-11 */
color: #2d3338 !important;
}
::placeholder {
color: #2d3338 !important;
}
.dropdown-menu {
min-width: 100% !important;
width: 100% !important;
padding: 0 !important;
margin: 5px 0 0 !important;
font-size: 14px !important;
border-width: 0 !important;
border-radius: 15px !important;
background-color: #fff !important;
max-height: 356px !important;
overflow-y: auto;
box-shadow: 0px 3px 20px #00000029 !important;
}

div ::-webkit-scrollbar-track {
background: #f1f1f1 !important;
}

/* Handle */
div ::-webkit-scrollbar-thumb {
background: #888 !important;
}

/* width */
::-webkit-scrollbar {
width: 12px;
}
/* Track */
::-webkit-scrollbar-track {
background: #445aa5;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555;
}

.dropdown-menu ul > li {
color: #000;
font-size: 14px;
line-height: 19px;
font-weight: normal;
padding: 0;
margin: 0;
text-align: left;
}
.dropdown-menu ul {
padding: 0 !important;
margin: 0 !important;
}
.dropdown-menu ul > li h3 {
color: #000;
font-size: 14px;
line-height: 19px;
font-weight: normal;
padding: 7px 8px;
margin: 0 0 8px;
text-align: left;
background-color: #efeff0 !important;
border-radius: 4px;
}
.dropdown-menu ul > li ul li {
padding: 0 8px 8px !important;
margin: 0;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
}
.dropdown-menu ul > li ul li a {
text-decoration: none;
padding: 0 !important;
margin: 0;
color: #000 !important;
}
.dropdown-menu ul > li ul li a span:first-child {
text-decoration: none;
color: #f15a24 !important;
}
.dropdown-menu ul > li ul li a:hover {
opacity: 0.8;
}

.profil-bar {
padding: 0 16px 0 0;
}
.profil-bar .dropdown-toggle {
display: flex;
align-content: center;
align-items: center;
padding: 4px !important;
margin: 0 !important;
color: #ffffff !important;
text-align: center;
font-size: 14px !important;
line-height: 16px !important;
font-weight: bold;
border: 0 solid transparent !important;
border-radius: 50% !important;
width: 46px;
height: 46px;
justify-content: center;
background: #ed1c24 !important;
box-shadow: 0px 3px 20px #ed1c24 34 !important;
}

.profil-bar .dropdown-toggle::after {
display: block;
position: absolute;
width: 0;
height: 0;
margin-left: 0.255em;
vertical-align: 0.255em;
content: "";
border-top: 0.3em solid;
border-right: 0.3em solid transparent;
border-bottom: 0;
border-left: 0.3em solid transparent;
right: -16px;
border-top-color: #000;
}
.profil-bar .dropdown-menu {
width: 205px !important;
left: -146px !important;
top: 0 !important;
margin: 0 !important;
padding: 16px 0 0 !important;
}

.profil-bar .dropdown-item {
padding: 0 !important;
}
.right_panel .btn-warning {
color: #ffffff;
border-width: 0 !important;
font-size: 16px;
line-height: 46px;
padding: 0 !important;
height: 46px !important;
border-radius: 50% !important;
width: 46px !important;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
background: #ed1c24 !important;
text-transform: uppercase;
}
.profil-bar .input-group {
position: relative;
margin: auto;
display: block;
width: auto;
padding: 0;
}
.profil-bar .upload_img_profil {
position: relative;
margin: 0 0 15px;
padding: 0;
}
.profil-bar .upload_info_profil {
position: relative;
margin: 0;
padding: 0 5px 14px;
border-bottom: solid 1px #ccc;
}
.profil-bar .upload_info_profil h3 {
color: #000000 !important;
font-size: 14px;
line-height: 17px;
padding: 0 0 2px;
margin: 0;
text-align: center;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
}
.profil-bar .upload_info_profil h4 {
color: #666666 !important;
font-size: 12px;
line-height: 18px;
font-weight: normal;
padding: 0 !important;
margin: 0 !important;
text-align: center;
border-width: 0 !important;
}
.profil-bar .upload_info_profil p {
color: #666666 !important;
font-size: 12px;
line-height: 18px;
font-weight: normal;
padding: 0;
margin: 0;
text-align: center;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
}
.profil-bar .upload_lien_profil {
position: relative;
margin: 0 0 10px;
padding: 0 5px 5px;
border-bottom: solid 1px #ccc;
}

.profil-bar .upload_lien_profil a {
color: #000000 !important;
font-size: 14px;
line-height: 19px;
font-weight: normal;
padding: 8px 11px;
margin: 0;
text-align: left;
border-radius: 6px;
background-color: #fff;
display: block;
}
.profil-bar .dropdown a {
color: #000000 !important;
font-size: 14px;
line-height: 19px;
font-weight: normal;
padding: 18px 11px !important;
margin: 0;
text-align: left;
border-radius: 0;
background-color: #fff;
display: block;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
}
.profil-bar .dropdown a.dropdown-item:last-child {
border-top: solid 1px #ccc;
margin-top: 0;
}
.profil-bar .dropdown a:hover {
opacity: 0.8;
background-color: rgba(0, 0, 0, 0.1) !important;
}
.profil-bar .upload_lien_profil a:hover {
background-color: #f4f4f5;
}
.profil-bar .upload_got_profil {
position: relative;
margin: 0;
padding: 0 5px 5px;
}
.profil-bar .upload_got_profil a {
color: #000000 !important;
font-size: 14px;
line-height: 19px;
font-weight: normal;
padding: 8px 11px;
margin: 0;
text-align: left;
border-radius: 6px;
background-color: #fff;
display: block;
}
.profil-bar .upload_got_profil a:hover {
background-color: #f4f4f5;
}
.zone_upload {
color: #fff;
font-size: 24px;
line-height: 58px;
position: relative;
width: 58px;
height: 58px;
margin: auto;
background: #ed1c24 !important;
display: flex;
align-content: center;
align-items: center;
border-radius: 50% !important;
justify-content: center;
font-weight: bold;
}
.custom-file {
cursor: pointer;
position: absolute;
width: 24px;
height: 24px;
margin: 0;
background: url(assets/img/Icon-camera.svg) center no-repeat #efefef !important;
right: -3px;
bottom: -5px;
display: flex;
align-content: center;
align-items: center;
border-radius: 50% !important;
justify-content: center;
border: solid 2px #fff;
}
.custom-file-input {
cursor: pointer;
position: relative;
z-index: 2;
width: 100%;
margin: 0;
opacity: 0;
}
.custom-file-label {
cursor: pointer;
position: absolute;
top: 0;
right: 0;
left: 0;
z-index: 1;
font-size: 0;
color: transparent !important;
}
.tooltip h2 {
color: #ffffff;
font-size: 14px;
line-height: 19px;
font-weight: normal;
padding: 0;
margin: 0;
text-align: left;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}
.tooltip p {
color: #ffffff;
font-size: 14px;
line-height: 19px;
font-weight: normal;
padding: 0;
margin: 0;
text-align: left;
font-family: "Gotham-Rounded-Light", Arial, sans-serif;
}
.navsidebar .dropdown {
background: linear-gradient(90deg, rgb(237, 28, 36) 0%, #ff2b3e 100%) 0% 0% !important;
border-radius: 15px;
min-height: 64px;
}
.navsidebar .btn {
position: relative;
font-size: 14px;
line-height: 16px;
font-weight: normal;
padding: 16px 20px 16px 55px;
margin: 0;
text-align: left;
display: block;
width: 100%;
text-decoration: none !important;
border-radius: 15px;
background: url("assets/img/icon-menu-Souscription_contrats.svg") left 15px
center no-repeat !important;
color: #fff !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
white-space: normal !important;
}
.navsidebar .dropdown-menu {
min-width: 100% !important;
width: 100% !important;
padding: 0 8px 10px !important;
margin: 0 !important;
font-size: 14px !important;
border-width: 0 !important;
border-radius: 15px !important;
background-color: transparent !important;
max-height: 356px !important;
overflow-y: auto;
box-shadow: none !important;
position: relative !important;
transform: translate(0px, 0px) !important;
}
.navsidebar .dropdown-toggle::after {
display: inline-block;
margin: 0;
border-width: 0;
background: url(assets/img/icon-arrow-right-menu.svg) center no-repeat;
width: 6px;
height: 11px;
position: absolute;
right: 11px;
top: 40%;
transition: all 0.2s ease-in-out;
}
.navsidebar .show.dropdown .dropdown-toggle::after {
transform: rotate(90deg);
}
.sidebar-wrapper #menu .dropdown .dropdown-menu a {
position: relative;
color: #ffffff !important;
font-size: 14px;
line-height: 16px !important;
font-weight: normal;
padding: 18px 15px !important;
margin: 0 0 5px;
text-align: left;
border-radius: 15px !important;
display: block;
width: 100%;
text-decoration: none !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
min-height: 45px;
}
.navsidebar .dropdown-item.active {
color: #445aa5 !important;
text-decoration: none;
background-color: #ffff !important;
}
.sidebar-wrapper .dropdown-item:focus,
.dropdown-item:hover,
.sidebar-wrapper #menu .dropdown .dropdown-menu a:focus,
.sidebar-wrapper #menu .dropdown .dropdown-menu a:hover {
color: #445aa5 !important;
background-color: #fff !important;
}
.sidebar-wrapper #menu .dropdown .dropdown-menu .dropdown-item.active,
.navsidebar .dropdown-item.active a {
color: #445aa5 !important;
text-decoration: none !important;
}
.sidebar-wrapper #menu li a:before {
display: block;
background: #fff;
opacity: 0.06;
position: absolute;
z-index: 0;
left: 0;
top: 0;
width: 0%;
height: 100%;
content: "";
border-radius: 15px;
transition: all 0.2s ease-in-out;
}
.sidebar-wrapper #menu li a:hover:before {
width: 100%;
height: 100%;
}

.sidebar-wrapper #menu li a.m2 {
background: url("assets/img/icon-menu-Documents_pratiques.svg") left 15px
center no-repeat;
}

.sidebar-wrapper #menu li a.m2.active {
background: url("assets/img/icon-menu-Documents_pratiques.svg") left 15px
center no-repeat,
linear-gradient(90deg, rgb(237, 28, 36) 0%, #ff2b3e 100%) 0% 0% !important;
color: #fff !important;
}

.sidebar-wrapper #menu li a.m3 {
background: url("assets/img/icon-menu-Produits.svg") left 15px center
no-repeat;
}

.sidebar-wrapper #menu li a.m3.active {
background: url("assets/img/icon-menu-Produits.svg") left 15px center
no-repeat,
linear-gradient(90deg, rgb(237, 28, 36) 0%, #ff2b3e 100%) 0% 0% !important;
color: #fff !important;
}

.sidebar-wrapper #menu li a.m4 {
background: url("assets/img/icon-menu-commande.svg") left 15px center
no-repeat;
}

.sidebar-wrapper #menu li a.m4.active {
background: url("assets/img/icon-menu-commande.svg") left 15px center
no-repeat,
linear-gradient(90deg, rgb(237, 28, 36) 0%, #ff2b3e 100%) 0% 0% !important;
color: #fff !important;
}

.sidebar-wrapper #menu li a.m5 {
background: url("assets/img/icon-menu-actus.svg") left 15px center no-repeat;
}

.sidebar-wrapper #menu li a.m5.active {
background: url("assets/img/icon-menu-actus.svg") left 15px center no-repeat,
linear-gradient(90deg, rgb(237, 28, 36) 0%, #ff2b3e 100%) 0% 0% !important;
color: #fff !important;
}
.modal {
display: flex !important;
align-content: center;
justify-content: center;
align-items: center;
}
.modal-backdrop {
background: #ffffff !important;
filter: blur(8px);
-webkit-filter: blur(8px);
}
#ModalProposition .modal-content {
border: 0 solid transparent !important;
border-radius: 15px !important;
box-shadow: 0 3px 20px #0000001a !important;
height: auto !important;
max-width: 520px !important;
overflow: hidden;
padding: 0 0 16px !important;
}
.ModalGestion .modal-content {
border: 0 solid rgba(0, 0, 0, 0) !important;
box-shadow: 0px 3px 20px #0000001a !important;
border-radius: 15px !important;
padding: 40px 25px !important;
overflow: hidden;
max-width: 650px !important;
height: auto !important;
text-align: center;
color: #000;
font-size: 16px;
}
.ModalGestion h2 {
color: #445aa5;
font-size: 20px;
line-height: 22px;
font-weight: normal;
padding: 25px 0 10px !important;
margin: 0;
text-align: center;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}
.ModalGestion .pictoGestion {
margin: auto;
width: 55px;
}
.ModalGestion.modal .btn {
color: #445aa5 !important;
border-color: #445aa5 !important;
border-width: 2px !important;
font-weight: bold;
}
.ModalGestion.modal .btn.btn-primary {
color: #fff !important;
border-color: #e73c4c !important;
background-color: #e73c4c !important;
border-width: 2px !important;
font-weight: bold;
}
.ModalGestion.modal .close_modal {
max-width: 15px;
opacity: 0.7;
}
.ModalGestion.modal .close_modal:hover {
max-width: 15px;
opacity: 1;
}

.modal-header {
padding: 0 !important;
border-width: 0 !important;
}
.modal-body {
padding: 0 !important;
overflow: hidden;
min-height: 0;
}
#ModalProposition .modal-dialog {
width: 485px !important;
max-width: 485px !important;
}
#DeletProposition .modal-body {
text-align: center;
}
.box_info {
width: 60px;
height: 60px;
border-radius: 50%;
background-color: rgba(126, 219, 195, 0.2);
padding: 16px;
margin: auto auto 30px !important;
display: block;
}
.ico_info {
margin: auto;
display: flex;
width: 100%;
height: 100%;
border-radius: 50%;
border: 2px solid #7edbc3;
padding: 0;
text-align: center;
font-size: 17px;
color: #7edbc3;
flex-direction: column;
align-content: center;
justify-content: center;
text-transform: lowercase;
}
.step {
margin: 0;
}
#ModalProposition h3,
.titleModal {
color: #ffffff;
font-size: 16px;
line-height: 19px;
font-weight: normal;
padding: 25px !important;
margin: 0 0 30px;
text-align: center;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
background-color: #445aa5;
}
.form-check {
padding-left: 0 !important;
text-align: left;
color: #000000;
font-size: 14px;
line-height: 19px;
margin-bottom: 8px !important;
display: flex !important;
justify-content: flex-start;
align-content: center;
align-items: center;
}
.form-check .form-check-input {
border: 2px solid #9aa0ac !important;
width: 22px !important;
height: 22px !important;
border-radius: 50% !important;
margin: 0 !important;
cursor: pointer;
padding: 0 !important;
}
.form-check label.form-check-label {
cursor: pointer;
padding-left: 8px !important;
text-align: left;
color: #000000;
font-size: 14px;
line-height: 19px;
margin: 0 !important;
}
#ModalProposition .form-check label.form-check-label {
max-width: 82%;
}
.form-check-input:checked[type="radio"] {
background: url("assets/img/checkmark.svg") no-repeat center #ed1c24 !important;
border-color: #ed1c24 !important;
}
.form-check-input:checked,
.form-check-input:focus,
.form-check-input:active,
.btn-close:focus,
.btn-close:active,
.btn:active,
.btn:focus {
outline: 0 !important;
outline-width: 0 !important;
outline-style: none !important;
box-shadow: none !important;
}
.modal-footer {
padding: 20px 0 10px !important;
border-width: 0 !important;
align-items: center !important;
justify-content: center !important;
}
/* .modal .btn {
min-width: 116px;
background: transparent !important;
color: #303030;
border-radius: 15px !important;
border-width: 0 !important;
padding: 12px 25px !important;
}
.modal .btn-primary {
color: #fff;
background: transparent linear-gradient(90deg,  rgb(237, 28, 36) 0%, #ff2b3e 100%) !important;
} */
.btn.disabled,
.btn:disabled {
cursor: not-allowed !important;
}
p {
color: #000000;
font-size: 14px;
line-height: 16px;
margin-bottom: 0 !important;
}
.arrowLeft,
.stepHidden,
.btn_step2,
.step2,
.btn_step3,
.step3 {
display: none !important;
}

.step1 {
transition: 0.5s ease all;
transform: translateX(100%);
transition-delay: 1s;
animation: blockactive 0.5s linear forwards;
}
.p-30 {
padding-left: 30px;
padding-right: 30px;
}
@keyframes blockactive {
0% {
transform: translateX(-100%);
}
100% {
transform: translateX(0%);
}
}
.step3,
.step2 {
padding: 0 35px 10px;
text-align: center;
}
.step3.stepShow,
.step2.stepShow {
transition: 0.5s ease all;
transform: translateX(100%);
transition-delay: 1s;
animation: blockactive 0.5s linear forwards;
}
@keyframes blockactive {
0% {
transform: translateX(-100%);
}
100% {
transform: translateX(0%);
}
}

.stepShow {
display: block !important;
text-decoration: none;
}
.modal .btn:active,
.modal .btn:focus,
.modal .btn:hover {
color: #fff !important;
background-color: #303030 !important;
border-color: #303030 !important;
}
.modal .btn-primary:active,
.modal .btn-primary:focus,
.modal .btn-primary:hover {
opacity: 0.8;
}
.modal .arrowLeft {
width: 21px;
position: absolute;
left: 25px;
top: 24px;
cursor: pointer;
z-index: 1;
}
.lienbread {
display: inline-block;
margin-right: 14px;
}
.ico_prec_title {
width: 21px;
float: left;
}
h2.titlecontent {
color: #000000;
font-size: 24px;
line-height: 26px;
font-weight: normal;
padding: 0;
margin: 0 !important;
text-align: left;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
display: flex;
align-content: center;
height: 100%;
align-items: center;
}

.content_section {
background: #ffffff;
box-shadow: 0px 0px 20px #5d00ff17;
border-radius: 10px;
padding: 24px 17px !important;
white-space: break-spaces;
word-break: break-word;
}
h2.title_section {
color: #445aa5;
font-size: 20px;
line-height: 22px;
font-weight: normal;
padding: 0 0 15px !important;
margin: 0 0 24px;
text-align: left;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
position: relative;
}
h2.title_section:before {
padding: 0;
margin: 0;
position: absolute;
display: block;
width: 58px;
height: 5px;
left: 0;
bottom: 0;
content: "";
background-color: #ed1c24;
box-shadow: 0px 0px 20px #5d00ff17;
border-radius: 10px;
}
.MuiFormControl-root {
width: 100%;
border-radius: 8px;
}
.box_form {
position: relative;
}
.txt_form {
padding-left: 0 !important;
color: #303030 !important;
font-size: 14px;
line-height: 16px;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
}
.MuiInputBase-root {
color: rgba(0, 0, 0, 0.87);
cursor: text;
display: inline-flex;
position: relative !important;
font-size: 14px !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
border-color: #9aa0ac !important;
border-radius: 8px !important;
}
input {
font-size: 14px !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
height: auto !important;
padding: 14px !important;
}
.MuiFormControl-root label {
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
color: #9aa0ac;
text-align: left;
font-size: 14px;
line-height: 18px;
background-color: #fff;
padding: 0 5px;
}
.contentGris2 .MuiFormControl-root label {
background-color: #f6f7fa;
}
input[type="date"].MuiOutlinedInput-input {
padding: 13px 16px !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
color: #303030;
text-align: left;
border-radius: 8px !important;
position: relative;
border-width: 1px;
border-color: #9aa0ac;
height: auto !important;
background: url("assets/img/Iconcalendarv1.svg") right 20px center no-repeat;
}
/*  fieldset {
border-width: 1px !important;
border-color: #9aa0ac !important;
}  

/* input[type="date"] {
color: #fff !important;
} */

.focus-input input,
.MuiFormLabel-filled input,
.Mui-focused input[type="date"] {
color: #303030 !important;
}
.form_n1 svg {
position: absolute;
right: 22px;
top: 12px;
fill: #9aa0ac;
width: 27px;
background: #fff;
padding: 8px;
}
.MuiOutlinedInput-root:hover input[type="date"].MuiOutlinedInput-input,
.Mui-focused input[type="date"].MuiOutlinedInput-input {
background: url("assets/img/Iconcalendarv2.svg") right 20px center no-repeat;
}
.error-valide input[type="date"].MuiOutlinedInput-input {
background: url("assets/img/Iconcalendarv3.svg") right 20px center no-repeat !important;
}
::-webkit-calendar-picker-indicator {
width: 20px;
height: 21px;
opacity: 0;
}
::-webkit-calendar-picker-indicator::after {
display: none;
}
/*
.focus-input input,*/
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover
.MuiSelect-select:before {
background: url("assets/img/chevron-down-solid-select.svg") right center
no-repeat;
opacity: 1;
}
/*.focus-input fieldset,*/
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover
.MuiOutlinedInput-notchedOutline,
.focusTextField fieldset,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
.MuiOutlinedInput-notchedOutline,
.Mui-focused fieldset {
border-color: #445aa5 !important;
border-width: 1px;
color: #000;
/* opacity: 1; */
}

.Mui-focused input {
border-color: #445aa5 !important;
/*  opacity: 1; */
}
input.MuiSelect-nativeInput.css-yf8vq0-MuiSelect-nativeInput {
border-color: transparent !important;
opacity: 0 !important;
}
.Mui-focused/*,
.focus-input label */ {
color: #445aa5;
}
.MuiCheckbox-colorPrimary.Mui-checked {
color: #ed1c24 !important;
border-radius: 6px !important;
}
.MuiCheckbox-colorPrimary {
width: 24px;
height: 24px;
border-radius: 6px !important;
appearance: none !important;
-webkit-appearance: none !important;
background-color: transparent !important;
cursor: pointer !important;
font-family: sans-serif;
border: 2px solid #9aa0ac !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
background-color: #ed1c24 !important;
border: 2px solid #ed1c24 !important;
}

.MuiCheckbox-colorPrimary svg {
display: none;
}
.MuiCheckbox-colorPrimary.Mui-checked:before {
position: absolute;
left: 0;
top: 0;
content: "";
background: url("assets/img/check-solid.svg") center no-repeat;
width: 100%;
height: 100%;
}
.MuiSvgIcon-root {
font-size: 1.7rem !important;
}

.box_checkbox {
padding-left: 0 !important;
margin-bottom: 8px;
}
.box_checkbox .MuiTypography-root {
font-size: 14px !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
line-height: 16px !important;
color: #303030 !important;
padding: 17px 16px;
background: #445aa519;
border-radius: 8px;
width: 100%;
display: flex;
}
.MuiCheckbox-root {
position: absolute !important;
left: 0 !important;
top: 11px !important;
padding: 0 !important;
}
.MuiFormControlLabel-root {
padding: 0 0 0 40px !important;
position: relative !important;
margin: 0 !important;
display: flex !important;
}
.MuiBox-root {
width: 100%;
}
.MuiSelect-select {
padding: 13px 60px 13px 16px !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
color: #303030;
text-align: left;
border-radius: 8px !important;
position: relative;
height: auto !important;
}
div.Select_Ville .MuiSelect-select,
div.Select_BK .MuiSelect-select {
opacity: 0.5;
}
div.Select_Bl .MuiSelect-select {
opacity: 1;
}
div.Select_R .MuiSelect-select {
opacity: 0.6;
}
/* .Selectionner_Opacity {
opacity: 0.7 !important;
} */
div.Select_Ville .MuiSelect-select:before ,
div.Select_BK .MuiSelect-select:before {
display: block;
position: absolute;
z-index: 0;
right: 15px;
top: 0;
width: 15px;
height: 100%;
content: "";
opacity: 1;
background: url("assets/img/chevron-down-solid-select.svg") right center
no-repeat;
}

div.Select_Bl .MuiSelect-select:before {
display: block;
position: absolute;
z-index: 0;
right: 15px;
top: 0;
width: 15px;
height: 100%;
content: "";
opacity: 1;
background: url("assets/img/arrow-down-select.svg") right center no-repeat;
}
div.Select_R .MuiSelect-select:before {
display: block;
position: absolute;
z-index: 0;
right: 15px;
top: 0;
width: 15px;
height: 100%;
content: "";
opacity: 1;
/*background: url("assets/img/arrow-down-select_R.svg") right center no-repeat;*/
}

.Mui-focused .MuiSelect-select:before {
opacity: 1;
}
.MuiOutlinedInput-root fieldset {
top: 0 !important;
}
.MuiOutlinedInput-root fieldset legend {
display: none;
}

.selectInput .MuiSelect-select {
padding: 13px 60px 13px 16px !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
color: #303030;
text-align: left;
border-radius: 8px !important;
position: relative;
height: auto !important;
}
.MuiSelect-select:before {
display: block;
position: absolute;
z-index: 0;
right: 15px;
top: 0;
width: 15px;
height: 100%;
content: "";
background: url("assets/img/chevron-down-solid-select.svg") right center
no-repeat;
opacity: 0.4;
}
.selectInput_v2 .MuiSelect-select:after {
display: block;
position: absolute;
z-index: 0;
right: 44px;
top: 17px;
width: 1px;
height: 16px;
content: "";
background-color: #707070;
}
.selectInput fieldset {
top: 0 !important;
}
.selectInput fieldset legend {
display: none;
}

.selectInput.Mui-focused input {
border-width: 0 !important;
opacity: 0 !important;
}
.MuiOutlinedInput-root svg {
width: 0 !important;
padding: 0 !important;
}
.MuiInputBase-root {
/*  color: #303030 !important; */
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-weight: normal !important;
text-align: left;
}
.modal .btn.btn-primary:disabled,
.btn.btn-primary:disabled,
.btn:disabled {
background: #0000001a !important;
color: rgba(0, 0, 0, 0.3) !important;
border-radius: 15px;
padding: 11px 20px;
float: right;
cursor: not-allowed !important;
pointer-events: auto !important;
}
.colcenter:before {
display: none;
}
.colcenter .cls_separation:before {
content: "";
width: 100%;
height: 2px;
background: #445aa5 0% 0% no-repeat padding-box;
opacity: 0.2;
border-radius: 15px;
position: absolute;
right: 0;
}
.colcenter .cls_separation {
float: left;
width: 12.44444444% !important;
position: relative;
}
.colcenter_4 .cls_separation {
width: 6.6666666% !important;
}
.colcenter .active_l::after,
.colcenter .active_r:before,
.cls_separation.active:before {
opacity: 1;
}
.boxStep {
width: 25% !important;
}
.colcenter_4 .boxStep {
width: 20% !important;
}
.title_form_simulation {
color: #445aa5;
font-size: 16px;
line-height: 32px;
padding: 0 !important;
margin: 0;
text-align: left;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif;
position: relative;
}
.title_form_simulation span {
color: #fff;
font-size: 16px;
line-height: 28px;
padding: 2px !important;
margin: 0 5px 0 0;
text-align: center;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
position: relative;
background-color: #ed1c24;
width: 32px;
height: 32px;
display: inline-block;
border-radius: 50%;
}
.hrPro {
padding: 0;
height: 1px !important;
background-color: #dadce0;
opacity: 1;
margin: 40px 0 !important;
}
.form_n1 .iconhelpR {
color: #445aa5 !important;
fill: #445aa5 !important;
width: 16px !important;
height: 16px !important;
padding: 0 !important;
cursor: pointer;
right: -11px !important;
top: 20px !important;
}
.boxTopTitle {
margin: 0 0 24px;
}

.btn_file {
background-color: transparent !important;
color: #445aa5 !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
font-size: 14px !important;
line-height: 16px !important;
border-radius: 15px !important;
padding: 14px 20px !important;
float: right !important;
cursor: pointer !important;
pointer-events: auto !important;
text-align: left !important;
border: 1px solid #445aa5 !important;
}

.btn_file img {
float: left;
margin-right: 16px;
}
.btn-default {
text-transform: none !important;
border-radius: 15px !important;
padding: 12px 20px !important;
cursor: pointer !important;
width: auto;
min-width: 138px !important;
font-family: "Gotham-Rounded-Medium", Arial, sans-serif !important;
}
#disabled {
background: #0000001a !important;
color: rgba(0, 0, 0, 0.3) !important;
border-radius: 15px;
padding: 11px 20px;
float: right;
cursor: not-allowed !important;
pointer-events: auto !important;
}
#disabled:hover {
background-color: #0000001a !important;
color: rgba(0, 0, 0, 0.3) !important;
}
#Tippy {
width: 10% !important;
align-items: center;
padding: auto;
}

.btnActive:hover,
.btn-primary.active,
.btn-primary:active,
.btn-primary:hover {
background-color: #ed1c24 !important ;
border-width: 0 !important;
opacity: 0.8;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover {
background-color: #ed1c24 !important ;
border-color: #ed1c24 !important ;
}
.boxTopTitle .btn-primary.active,
.boxTopTitle .btn-primary:active,
.boxTopTitle .btn-primary:hover {
background-color: transparent !important ;
border-width: 1px !important ;
}
.align-center {
display: flex;
justify-content: flex-start;
align-items: center;
}
.boxCheckScrt .form-check .form-check-input {
width: 26px !important;
height: 26px !important;
padding: 0 !important;
}

.boxCheckScrt .form-check .form-check-input:checked[type="radio"] {
background: #fff !important;
border: 8px solid #ed1c24 !important;
}
.dektop {
display: block !important;
}
.mobile {
display: none !important;
}
.Modal-default .modal-content {
border-width: 0 !important;
box-shadow: 0px 3px 20px #00000029 !important;
border-radius: 15px !important;
padding: 24px !important;
}
.Modal-default .modal-dialog {
max-width: 680px !important;
width: 680px !important;
}
.Modal-default h3 {
color: #445aa5;
font-size: 24px;
line-height: 24px;
font-weight: normal;
padding: 0 0 32px !important;
margin: 0;
text-align: center;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
}
.Modal-default p {
color: #000;
font-size: 14px;
line-height: 18px;
font-weight: normal;
padding: 0 !important;
margin: 0 !important;
text-align: center;
}
.Modal-default .modal-footer {
padding: 20px 0 0 !important;
border-width: 0 !important;
align-items: center !important;
justify-content: center !important;
}
/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
color: #445aa5 !important;
} */
input:-webkit-autofill,
input:-webkit-autofill:focus {
transition: background-color 600000s 0s, color 600000s 0s;
}
.mt_20 {
margin-top: 20px !important;
}
.mt_15 {
margin-top: 15px !important;
}
.mb_40 {
margin-bottom: 40px !important;
}
.mb_24 {
margin-bottom: 24px !important;
}
.mb_20 {
margin-bottom: 20px !important;
}
.mb_15 {
margin-bottom: 15px !important;
}
.mb_10 {
margin-bottom: 10px !important;
}
.css-u9osun.Mui-focused,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
color: #445aa5 !important;
}
.recharts-tooltip-wrapper {
background: transparent;
border-radius: 14px;
}
.recharts-tooltip-wrapper .recharts-default-tooltip {
background: #ffffff 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 16px #445aa54a;
border-radius: 14px;
}
.recharts-tooltip-wrapper .recharts-default-tooltip li {
padding-bottom: 0 !important;
font-size: 12px;
}
.loading {
position: fixed;
z-index: 9999;
background: rgba(255, 255, 255, 0.9);
width: 100%;
height: 100%;
left: 0;
top: 0;
display: flex;
justify-content: center;
align-items: center;
}
.blockTabDep{
    position: relative;
}
.loadingTab{
    position: absolute;
}
.loading .boxLoading {
width: 94px;
display: block;
margin: auto;
position: relative;
padding: 24px;
}
.loading .boxLoading img {
display: block;
margin: auto;
}
.loading .boxLoading .svgrotation {
display: block;
margin: auto;
-webkit-animation: mymove 1s infinite linear;
position: absolute;
left: 0;
top: 0;
}
@keyframes mymove {
0% {
transform: rotate(0deg);
}
25% {
transform: rotate(180deg);
}
50% {
transform: rotate(360deg);
}
75% {
transform: rotate(180deg);
}
100% {
transform: rotate(0deg);
}
}
.errorMsg {
color: #ed1c24;
font-size: 12px;
float: left;
clear: both;
display: block;
width: 100% !important;
margin: 0;
position: absolute;
left: 15px;
bottom: -18px;
padding: 0 !important;
}
.errorMsgStyle1 {
position: relative;
left: 0 !important;
bottom: 0 !important;
}
.pl_0 {
padding-left: 0 !important;
}
.pl_8 {
padding-left: 8px !important;
}
.pr_0 {
padding-right: 0 !important;
}
.fl_left {
float: left;
}
.error-valide input,
.error-valide .css-u9osun.Mui-focused,
.error-valide .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.error-valide label {
color: #ed1c24 !important;
}
.Mui-error.Mui-focused fieldset,
.error-valide fieldset,
.error-valide .MuiOutlinedInput-root fieldset,
.error-valide input,
.error-valide
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover
.focusTextField
fieldset,
.error-valide
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
.MuiOutlinedInput-notchedOutline,
.error-valide .Mui-focused fieldset,
.error-valide .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
border-color: #ed1c24 !important;
}
.focus-input.error-validet input,
.focus-input.error-valide input,
.focus-input.error-valide input[type="date"] {
color: #ed1c24 !important;
}
.error-valide-body ::-webkit-calendar-picker-indicator {
background-image: url("assets/img/calanderError.svg") !important;
}
.error-valide-body ::-webkit-calendar-picker-indicator::after {
content: "";
display: block;
background: url("assets/img/calanderError.svg") no-repeat !important;
background-size: 10%;
width: 100px;
height: 100px;
position: absolute;
transform: translateX(-2%);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type="number"] {
-moz-appearance: textfield;
}
.p-relative {
position: relative;
}
#loadingAfterStep1 {
position: absolute;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
-webkit-text-fill-color: #303030;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
animation: listeactive linear forwards;
animation-delay: 2s;
}

@keyframes listeactive {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}
.Pays_de_naissance div#demo-simple-select,
.Indicatif div#demo-simple-select {
opacity: 1 !important;
}
.textToggle2 {
margin-left: 15px;
}
#loaderBtn {
float: left;
width: 0px;
margin: 0 0 0 -12px;
height: 0;
overflow: hidden;
transition: all 0.5s ease-in-out;
}
#loaderBtn.toggled {
width: 37px;
height: 22px;
display: inline-block;
}
.loader {
width: 20px;
height: 20px;
border: 3px dotted rgba(0, 0, 0, 0.3) !important;
border-style: solid solid dotted dotted;
border-radius: 50%;
display: inline-block;
position: relative;
box-sizing: border-box;
animation: rotation 2s linear infinite;
}
.loader::after {
content: "";
box-sizing: border-box;
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
margin: auto;
border: 0 dotted #ff3d00;
border-style: solid solid dotted;
width: 0;
height: 0;
border-radius: 50%;
animation: rotationBack 1s linear infinite;
transform-origin: center center;
}

@keyframes rotation {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}
@keyframes rotationBack {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(-360deg);
}
}
.errorServer {
text-align: right;
padding-top: 15px !important;
}
.succesDnw {
background: #e73c4c;
border-radius: 50%;
padding: 16px;
height: 61px;
width: 61px;
overflow: hidden;
}
.img-responsive {
max-width: 100%;
}
.modal-header .btn-close {
right: -14px !important;
top: -13px !important;
position: absolute;
z-index: 99999999;
font-size: 23px !important;
}
.imgpsh {
border-radius: 15px;
}
.close_modal {
position: absolute;
right: 10px;
top: 10px;
z-index: 9999;
cursor: pointer;
}

#ModalDocs .modal-content {
width: 100% !important;
}
.selectInput_v2.active {
display: none;
}
.mt-15 {
margin-top: 15px !important;
}
.pl_25 {
padding-left: 25px;
}
.pr_25 {
padding-right: 25px;
}
.hideV0 {
display: none !important;
}
.titelred {
color: #ed1c24 !important;
font-family: Gotham-Rounded-Bold, Arial, sans-serif;
}
.ModalExpire .modal-body {
text-align: center;
}
.ModalQuit .modal-body {
padding-top: 32px !important;
text-align: center;
}
.pagenotfound {
background: #fff 0 0 no-repeat padding-box;
border-radius: 10px;
box-shadow: 0 0 20px #5d00ff17;
min-height: calc(100vh - 110px);
overflow: hidden;
padding: 16px;
text-align: center;
color: #666666;
font-size: 14px;
display: flex;
flex-direction: column;
flex-wrap: nowrap;
align-content: center;
justify-content: center;
align-items: center;
}
.pagenotfound h2 {
padding: 16px 16px 37px !important;
text-align: center;
color: #e73c4c;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
margin: 0 !important;
font-size: 20px !important;
line-height: 26px;
}
.erreur500 h2 {
padding-bottom: 80px !important;
}
.pagenotfound img {
margin: 0 0 21px !important;
}

.pagenotfound p {
padding: 0 30% !important;
text-align: center;
color: #666666;
}
.pagenotfound .btn {
background-color: #ed1c24 !important;
border-color: #ed1c24 !important;
margin-top: 24px !important;
}
@media (max-width: 1600px) {
.row-MV .col-2 {
width: 25%;
}
.row-MV .col-10 {
width: 75%;
}
.contentGris2 .divInput .inputVersement .MuiFormLabel-root {
font-size: 12px !important;
}
}
@media (max-width: 1400px) {
#ModalDocs .modal-content {
width: 80% !important;
display: flex;
align-content: center;
justify-content: center;
align-items: center;
flex-wrap: nowrap;
flex-direction: column;
margin: auto;
}
}
@media (max-width: 1200px) {
.row-MV .col-2 {
width: 40%;
}
.row-MV .col-10 {
width: 60%;
}
}
@media (min-width: 1024px) AND (max-width: 1350px) {
.wrapper.toggled .content-box-step {
padding: 19px 16px 6px !important;
}
.boxStep .content-box-step {
padding: 40px 16px 19px !important;
justify-content: center !important;
}
.wrapper.toggled .textBox {
padding-left: 36px !important;
font-size: 14px !important;
}
.textBox {
padding-left: 0 !important;
font-size: 12px !important;
}
.wrapper.toggled .divNum {
width: 30px !important;
height: 30px !important;
font-size: 14px !important;
left: 24px !important;
top: 15px;
}
.divNum {
width: 30px !important;
height: 30px !important;
font-size: 14px !important;
left: 40% !important;
top: 8px;
}
}
@media (max-width: 1024px) {
#ModalDocs .modal-content {
width: 767px !important;
}
.divNum {
width: 30px !important;
height: 30px !important;
font-size: 14px !important;
left: 40% !important;
top: 8px;
}
.textBox {
padding-left: 0 !important;
font-size: 12px !important;
}
.boxStep .content-box-step {
padding: 40px 16px 19px !important;
}
}
@media (min-width: 992px) {
    .superadmin .rdt_TableBody .rdt_TableCell:nth-child(6),
    .superadmin .rdt_TableHeadRow .rdt_TableCol:nth-child(6) {
    max-width: 160px !important;
    min-width: 160px !important;
    }
    .rdt_TableBody .rdt_TableCell:nth-child(7),
    .rdt_TableHeadRow .rdt_TableCol:nth-child(7) {
    max-width: 195px !important;
    min-width: 195px !important;
    }

.superadmin .rdt_TableBody .rdt_TableCell:nth-child(7),
.superadmin .rdt_TableHeadRow .rdt_TableCol:nth-child(7)  {
max-width: 110px !important;
min-width: 110px !important;
}
.superadmin .rdt_TableBody .rdt_TableCell:nth-child(5),
.superadmin .rdt_TableHeadRow .rdt_TableCol:nth-child(5) {
max-width: 395px !important;
min-width: 395px !important;
}
.rdt_TableBody .rdt_TableCell:nth-child(6),
.rdt_TableHeadRow .rdt_TableCol:nth-child(6) {
max-width: 335px !important;
min-width: 335px !important;
}
.superadmin .rdt_TableBody .rdt_TableCell:nth-child(3),
.superadmin .rdt_TableHeadRow .rdt_TableCol:nth-child(3){
max-width: 135px !important;
min-width: 135px !important;
}
.rdt_TableBody .rdt_TableCell:nth-child(4),
.rdt_TableHeadRow .rdt_TableCol:nth-child(4),
.rdt_TableBody .rdt_TableCell:nth-child(5),
.rdt_TableHeadRow .rdt_TableCol:nth-child(5) {
max-width: 105px !important;
min-width: 105px !important;
}
.modal-dialog {
max-width: 100% !important;
}
.wrapper.toggledd .bloc_logo {
display: inline-block;
position: relative;
padding-left: 0;
padding-right: 6px;
}
.wrapper.toggled .sidebar-header .bloc_burger {
margin-left: 0 !important;
display: inline-block !important;
}
.toggled .sidebar-header {
padding-left: 0 !important;
position: relative;
}
.wrapper.toggled .page-content-wrapper {
margin-left: 0px;
}
.wrapper.toggled .sidebar-wrapper {
left: -350px !important;
overflow: hidden;
}
.wrapper.toggled #menu a {
display: none !important;
}
.wrapper.toggled .close_menu {
display: none !important;
}
}

@media (max-width: 992px) {
.erreur500 h2 {
padding-bottom: 80px !important;
}
.pagenotfound p {
padding: 0 25px !important;
}
.row-MV .col-10 {
width: 100%;
padding: 0 !important;
display: flex;
flex-direction: row-reverse;
align-items: center;
justify-content: center;
flex-wrap: nowrap;
align-content: center;
}
.row-MV .col-10 .inputRe {
margin-bottom: 0 !important;
}
.download-table-xls-button {
margin-left: 0 !important;
}
.Nouvelle {
margin-right: 0 !important;
}
.bloc_panel .col-6 {
width: 100%;
padding: 0 !important;
}
.boxfiltre .btn-default {
width: 100%;
margin: 0 0 15px !important;
}
.col-2.colstart.divPro {
padding: 0 0 5px !important;
margin: 0 0 15px !important;
}
.filtreZone {
padding: 0 0 24px !important;
}
.boxfiltre .col-6 {
padding-left: 0 !important;
width: 100%;
}
.boxfiltre .colstart {
display: flex;
justify-content: start;
align-items: flex-start;
position: relative;
flex-direction: column;
}
.row-MV .col-2 {
width: 100%;
}
#ModalDocs .modal-content {
width: 100% !important;
}
.contentGris2 .col-xs-12.col-md-6.divInput {
position: relative;
}
.cls_content_fiche .cls_box_fiche.cls_box_fiche_4 {
margin: 15px -15px !important;
}
.cls_left p small {
margin: 10px 0 !important;
}
.page-wrapper
.cls_box_fiche.cls_box_fiche_3
.table
tr:last-child
td:last-child {
border-radius: 0 0 10px 10px !important;
}
.page-wrapper
.cls_box_fiche.cls_box_fiche_3
.table
tr:last-child
td:first-child {
border-radius: 10px 10px 0 0 !important;
}
.page-wrapper .cls_box_fiche.cls_box_fiche_3 .table th:last-child,
.page-wrapper .cls_box_fiche_3 .table td:last-child,
.page-wrapper
.cls_content_fiche
.cls_box_fiche.cls_box_fiche_3
.table
tbody
tr:first-child
td,
.cls_content_fiche .cls_box_fiche .table td {
width: 100% !important;
float: left;
text-align: left !important;
}
.page-wrapper
.cls_content_fiche
.cls_box_fiche.cls_box_fiche_3
.table
tbody
tr:first-child
td {
padding-top: 0 !important;
}
.navigationSection .btn {
margin-left: 0 !important;
margin-bottom: 14px;
}
.boxCheckedyesno {
margin: 16px 0 24px;
}
.contentGris2 {
margin: 0 0 24px;
}
.colend {
flex-direction: column;
}
.col-xs-12.xs-offset-0.col-sm-12.sm-offset-0.col-md-4.offset-md-0.colend {
flex-direction: row;
}
.navigationSection,
.mt-24,
.mt-50 {
margin-top: 0 !important;
}
.contentBoxGris {
display: flex;
justify-content: space-between;
align-items: stretch;
flex-direction: column;
}
.blocDocTelecharge .listeChoixDocDnw .col-md-5 {
width: 100%;
}

.content_section .recharts-responsive-container,
.content_section .recharts-wrapper {
height: auto !important;
}
.content_section .recharts-legend-wrapper {
width: 100% !important;
height: auto !important;
top: 0 !important;
display: flex;
justify-content: center;
align-items: center;
margin: auto !important;
position: relative !important;
left: 0 !important;
bottom: 0 !important;
}
.modal-dialog,
.Modal-default .modal-dialog {
max-width: 100% !important;
width: 100% !important;
}
.btnActiveTele {
margin-top: 15px !important;
}
.form-control,
.errorMsg,
.mb-40 .MuiFormControl-root {
margin-bottom: 15px !important;
}
.mb-40 {
margin-bottom: 0px !important;
}
.lienbread {
margin: 5px 14px 0 0;
float: left;
}
.divTxB {
margin-bottom: 16px;
}
.btn-default {
font-size: 14px !important;
}
.box_blue {
margin-bottom: 0 !important;
background: transparent !important;
border-left: 16px solid #f6f7fa;
border-right: 16px solid #f6f7fa;
border-radius: 0;
padding: 0 !important;
}
.box_blue h2 {
background: #445aa5;
padding: 16px 16px 8px !important;
text-align: left;
color: #ffffff;
font-family: "Gotham-Rounded-Bold", Arial, sans-serif;
margin: 0 !important;
font-size: 16px !important;
line-height: 22px;
border-radius: 15px 15px 0 0;
}
.box_blue h2 img {
float: left;
}
.box_blue h3 {
background: #445aa5;
padding: 16px !important;
margin: 0 !important;
}
.box_blue h3 img {
margin-right: 10px;
}
.box_blue p {
background: #445aa5;
font-size: 14px !important;
border-radius: 0 0 15px 15px;
padding: 0 16px 18px !important;
}
.box_white {
background: #445aa5 !important;
height: 500px !important;
}

.recharts-legend-item-text,
tspan {
font-size: 8px !important;
}
.divStat {
padding: 0;
}
.clsTitle {
text-align: center;
}
.textTitle {
font-size: 18px !important;
padding-bottom: 16px !important;
}
.text2,
.text1 {
font-size: 14px !important;
line-height: 22px !important;
}
.contentGris2 .mb_40 {
margin-bottom: 15px;
}
.contentGris2 {
background: #f6f7fa;
border-radius: 15px;
padding: 16px !important;
}
.contentBoxGris .btn {
margin: 15px auto 0;
width: 100%;
font-size: 14px;
}
.contentBoxGris {
line-height: 22px !important;
}
.mb-xs-15 {
margin-bottom: 15px;
}
.boxStep {
width: 100% !important;
}
.boxStep .content-box-step {
padding: 19px 16px !important;
}
.textBox {
padding-left: 36px !important;
font-size: 14px !important;
}
.divNum {
width: 30px !important;
height: 30px !important;
font-size: 14px !important;
left: 24px !important;
top: 15px;
}
.colcenter_3.boxStep {
width: 100% !important;
margin-bottom: 9px;
margin-top: 5px;
}
.colcenter_3 .cls_separation {
width: 100% !important;
height: 25px !important;
margin: 7px 0;
}
.colcenter .cls_separation:before {
width: 2px !important;
height: 25px !important;
right: 0 !important;
left: 49% !important;
}
.boxStep:before {
right: 0 !important;
left: 48.5% !important;
top: 100% !important;
}
.colcenter {
padding: 0 0 15px !important;
}
.boxStep:after {
right: 0 !important;
left: 48.5% !important;
top: -10% !important;
}
.wrapper .form_n2 h4 {
padding: 0 0 25px !important;
}
.form_n1 .iconhelpR {
right: -21px !important;
}
.txt_form {
padding-left: 0 !important;
padding-right: 0 !important;
margin: 0 0 24px !important;
}
.box_form {
padding-left: 0 !important;
padding-right: 0 !important;
}
.boxTopTitle > div {
padding-left: 0;
padding-right: 0;
}
h2.title_section {
font-size: 18px;
line-height: 22px;
}
h2.titlecontent {
margin: 0 0 24px !important;
font-size: 20px;
display: block !important;
height: auto !important;
}
.dektop {
display: none !important;
}
.mobile {
display: block !important;
}
.wrapper .sidebar-wrapper {
left: -350px !important;
}
.wrapper .page-content-wrapper {
margin-left: 0px;
}
.page-content {
padding: 15px;
}
.wrapper .sidebar-header .bloc_logo {
display: inline-block;
position: relative;
margin: 0 15px 0 0 !important;
}
.wrapper .sidebar-header .bloc_burger {
margin-left: 0 !important;
float: right;
margin: 7px 15px 0 0;
}
.toggled .sidebar-wrapper {
width: 100%;
}
.wrapper.toggled .sidebar-wrapper {
left: 0 !important;
padding: 15px;
}
.wrapper.toggled .page-wrapper {
display: none !important;
}
.close_menu {
display: none !important;
position: absolute;
right: 15px;
top: 15px;
z-index: 9;
}
.wrapper .sidebar-header .bloc_burger,
.wrapper.toggled .sidebar-wrapper .close_menu {
display: block !important;
}
.wrapper.toggled .sidebar-header .bloc_burger,
.wrapper .sidebar-wrapper .close_menu {
display: none !important;
}

.profil-bar {
padding: 0;
}
.sidebar-header {
display: flex;
justify-content: space-evenly;
flex-direction: row-reverse;
}
.navsidebar .dropdown {
min-height: 20px;
}
.navsidebar .btn {
padding: 21px 20px 21px 55px;
}
.navsidebar .dropdown-toggle::after {
right: 20px;
}
}

@media (max-width: 767px) {
#formSimulation .colstart {
display: flex;
justify-content: start;
align-items: stretch;
position: relative;
flex-direction: column;
}
.DivStat {
padding: 24px 0px !important;
}
.clsTable.box3.table-responsive {
padding-left: 0 !important;
padding-right: 0 !important;
}
.content_section {
padding: 24px 0 !important;
}
table.tableRentes th:first-child {
min-width: 115px !important;
font-size: 10px !important;
}
.tableRentes tr td.td1,
.tableRentes tr td {
font-size: 10px !important;
min-width: 100px !important;
}
table.tableRentes th {
font-size: 10px !important;
}
}
